import { Input, InputSelect } from "../../atoms/Input/Input";
import styles from "./FormInput.module.css";

export const FormInput = (props) => {
  return (
    <>
      <label className={styles.Label}>{props.label}</label>
      <Input {...props} />
    </>
  );
};
export const FormSelectInput = (props) => {
  return (
    <>
      <label className={styles.Label}>{props.label}</label>
      <InputSelect {...props} />
    </>
  );
};

