import styles from "./Button.module.css";
import {withRouter} from 'react-router-dom';

export const Button = withRouter((props) => {
  return (
    <button
      onClick={props.clicked}
      style={{
        borderRadius: props.noBorderRadius ? "0px" : null,
        border: props.outlined ? "1px solid #000" : null,
        background: props.outlined ? "transparent" : "#000",
        color: props.outlined ? "#000" : "#fff",
        ...props.buttonStyles,
      }}
      className={styles.Button}
      type={props.type}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
});

export const TextButton = (props) => {
  return (
    <button onClick={props.clicked} style={{...props.style}} className={styles.TextButton}>
      {props.children}
    </button>
  );
};
