import styles from './ServiceCard.module.css';
import line from '../../../../../assets/icons/Line.png';
import { Button } from '../../../atoms/Button';

function ServiceCard(props) {
  let title, description;
  switch (props.accountType) {
    case "target-savings":
      title = "Target Savings";
      description =
        "Suitable for those who need cash to meet expenses that are recurrent or to acquire asset for productive purpose like house rents, family holidays, car purchase, children school fees, office equipment etc.";
      break;
    case "daily-savings":
      title = "Daily Savings";
      description =
        "Availability of your funds with competitive interest rates without forfeiture of interest in case of withdrawal.";
      break;
    case "current-savings":
      title = "Current Savings";
      description =
        "Provides unlimited access to your money at all times. It is available for both individuals and corporate bodies.";
      break;
    case "investment":
      title = "Investment Savings";
      description =
        "Grow your income as you keep saving and earn monthly interest of up to 10% per Annum. Lock-in your savings investment and earn up front interest of up to 14%.";
      break;
    default:
      return null;
  }
    return (
      <div className={styles.ServiceCard}>
        {props.popular ? <span className={styles.Popular}>Popular</span> : null}
        <h1>{title}</h1>
        <img src={line} alt="" />
        <p>{description}</p>
        <a href={`mailto:info@thinkfinancemfb.com?subject=${title} Inquiry`}>
        <div className={styles.ButtonWrap}>
        <Button buttonStyles={{padding: '16px 24px'}}>Contact us</Button>
        </div>
        </a>
      </div>
    );
}

export default ServiceCard
