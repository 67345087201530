import Layout from "../../hoc/Layout";
import styles from "./LoanPolicy.module.css";

const LoanPolicy = () => {
  return (
    <Layout>
      <div className={styles.LoanPolicy}>
        <h1>Loan Policy</h1>
        <h2>TF Microfinance Bank Internet/Mobile Banking Loan Policy</h2>
        <p>
          This policy outlines the guidelines and procedures for granting loans
          via TF Microfinance Bank's internet and mobile banking platforms. It
          aims to provide a seamless, secure, and efficient process for
          customers seeking financial assistance through our digital channels.
        </p>
        <h2>Eligibility Criteria</h2>
        <p>
          To qualify for an internet/mobile banking loan from TF Microfinance
          Bank, applicants must meet the following criteria:
        </p>
        <ul>
          <li>
            Be an existing customer of TF Microfinance Bank with a valid
            account.
          </li>
          <li>Have an active account with us.</li>
          <li>Be at least 21 years old.</li>
          <li>Have a stable source of income or verifiable employment.</li>
          <li>Have a good credit history with no outstanding loan defaults.</li>
        </ul>
        <h2>Loan Application Process</h2>
        <p>
          <span>Login: </span>Customers must log in to their internet or mobile
          banking account.
        </p>
        <p>
          <span>Application Form: </span>Complete the online loan application
          form with accurate personal, employment, and financial information.
        </p>
        <p>
          <span>Loan Amount: </span>Specify the desired loan amount and
          repayment period.
        </p>
        <p>
          <span>Documentation: </span>Upload necessary documents, including
          identification, proof of income, and any other required documents.
        </p>
        <p>
          <span>Submission: </span>Submit the loan application for review.
        </p>
        <h2>Loan Approval Process</h2>
        <p>
          <span>Initial Screening: </span>The system performs an initial
          screening based on the eligibility criteria.
        </p>
        <p>
          <span>Credit Check: </span>A credit check is conducted to assess the
          applicant's creditworthiness.
        </p>
        <p>
          <span>Review: </span>The loan application is reviewed by the loan
          officer for approval.
        </p>
        <p>
          <span>Approval/Denial: </span>The customer is notified of the loan
          approval or denial via email and SMS.
        </p>
        <p>
          <span>Agreement: </span>Approved applicants must accept the loan terms
          and conditions digitally.
        </p>

        <h2>Loan Terms and Conditions</h2>

        <p>
          <span>Loan Amount: </span>The minimum and maximum loan amounts will be
          determined based on the customer's credit profile and account history.
        </p>
        <p>
          <span>Interest Rate: </span>Interest rates are fixed or variable,
          based on market conditions and customer risk assessment.
        </p>
        <p>
          <span>Repayment Period: </span>The repayment period ranges from 3
          months to 24 months.
        </p>
        <p>
          <span>Repayment Method: </span>Repayments are automatically deducted
          from the customer's account on the due date.
        </p>

        <h2>Fees and Charges</h2>

        <p>
          <span>Processing Fee: </span>A non-refundable processing fee is
          charged upon approval of the loan.
        </p>
        <p>
          <span>Late Payment Fee: </span>A penalty fee is applied for late
          payments.
        </p>
        <p>
          <span>Early Repayment Fee: </span>An early repayment fee may be
          charged if the loan is paid off before the end of the term.
        </p>
        <h2>Security and Privacy</h2>
        <p>
          TF Microfinance Bank ensures that all loan applications and
          transactions are conducted securely through encrypted channels.
          Customer data is protected in accordance with our privacy policy and
          relevant data protection regulations.
        </p>
        <h2>Customer Support</h2>
        <p>
          Customers can contact our customer support team via phone, email, or
          chat for assistance with their loan applications and any related
          queries.
        </p>
        <h2>Policy Review</h2>
        <p>
          This loan policy is subject to periodic review and may be updated to
          reflect changes in regulations, market conditions, or bank policies.
        </p>
        <h2>Conclusion</h2>
        <p>
          TF Microfinance Bank is committed to providing accessible and
          convenient loan services through our internet and mobile banking
          platforms. By adhering to this policy, we ensure that our customers
          receive fair, transparent, and efficient financial assistance.
        </p>
        <h2>More information</h2>
        <p>
          For more information or assistance, please contact our customer
          support team at <em> </em>
          <a
            href="mailto:itsupport@thinkfinancemfb.com"
            style={{ color: "#012C7F" }}
          >
            itsupport@thinkfinancemfb.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default LoanPolicy;
