import { NavLink } from "react-router-dom";

const NavLinks = () => {
  return (
    <ul>
      <NavLink to="/">
        <li>Home</li>
      </NavLink>
      <NavLink to="/about-us">
        <li>About Us</li>
      </NavLink>
      <a href="https://tfmicrofinance.evolvecredit.io/auth/register">
        <li>Loans</li>
      </a>
      <a href="https://ibank.mybankone.com/thinkfinance">
        <li>Internet Banking/Open an account</li>
      </a>
    </ul>
  );
};

export default NavLinks;
