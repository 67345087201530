import Layout from '../../hoc/Layout'
import LoanDescription from '../../UI/organisms/LoanDescription/LoanDescription'
import TopSection from '../../UI/organisms/LoanDetailsTopSection/TopSection'
import OtherLoans from '../../UI/organisms/OtherLoans/OtherLoans'
import styles from './LoanDetails.module.css'

const BusinessLoanDetails = () => {
    const description = <p>
    Specifically, for business owners and can be used to finance working capital and equipment purchase.
  </p>
    const requirements = <>
    <li>Business must have been existing for at least a year.</li>
    <li>Evidence of business location.</li>
    <li>At least a guarantor must be produced.</li>
    </>
    
    const features = <>
    <li>Flexible repayment Plan.</li>
    <li>Should produce at least a guarantor.</li>
    <li>Free advisory service.</li>
    </>
    const otherLoans = ['consumer', 'asset-lease', 'education'];
    return (
        <Layout>
        <div className={styles.LoanDetails}>
            <TopSection title="Business Loan" />
            <LoanDescription loanType="business" features={features} requirements={requirements} description={description} />
            <OtherLoans otherLoans={otherLoans} />
        </div>
        </Layout>
    )
}

export default BusinessLoanDetails
