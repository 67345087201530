import styles from './LoanSideBar.module.css';

const LoanSideBar = () => {
  return (
    <div className={styles.LoanSideBar}>

    </div>
  )
}

export default LoanSideBar