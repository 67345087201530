import Layout from "../../hoc/Layout";
import FAQ from "../../UI/organisms/FAQ/FAQ";
import LoanApplication from "../../UI/organisms/LoanApplication/LoanApplication";
import LoanServices from "../../UI/organisms/LoanServices/LoanServices";
import TopSection from "../../UI/organisms/ServicesTopSection/TopSection";
import styles from "./Services.module.css";

const Services = () => {
  return (
    <Layout>
      <div className={styles.Services}>
        <TopSection />
        <LoanApplication />
        <LoanServices />
        <FAQ />
      </div>
    </Layout>
  );
};

export default Services;
