import styles from './OurMission.module.css';

const OurMission = () => {
    return (
        <div className={styles.OurMission}>
            <div className={styles.Wrapper}>
                <h5>our mission</h5>
                <p>We’re on a mission to <span>bank the unbanked and underbanked</span></p>
                <p>We always look to create new and innovative ways that connect the un-banked and underbanked to financial services. </p>
            </div>
        </div>
    )
}

export default OurMission
