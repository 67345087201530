import React, {Component, Fragment} from 'react';
import styles from "./Modal.module.css";
import Backdrop from "../../atoms/Backdrop/Backdrop";

class Modal extends Component {

  shouldComponentUpdate(nextProps, nextState){
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
  }
  render() {
    return (
      <Fragment>
        <Backdrop clicked={this.props.modalClosed} show={this.props.show} />
        <div
          className={styles.Modal}
          style={{
            transform: this.props.show ? "translateY(0)" : "translateY(20vh)",
            opacity: this.props.show ? "1" : "0",
            zIndex: this.props.show ? 98 : -1,
          }}
        >
          {this.props.children}
        </div>
      </Fragment>
    );
  }
}

export default Modal;
