import styles from "./OurStory.module.css";

function OurStory() {
  return (
    <div className={styles.OurStory}>
      <h3 className={styles.Title}>Our Story</h3>
      <div className={styles.Wrapper}>
        <p className={styles.Content}>
        The vision for TF Microfinance Bank started with the Incorporation of Thinkfinance Services Limited on 6th December 2012. In 2015 the company was granted an approval in principle by The Central Bank of Nigeria for a Unit Microfinance Bank. On the 14th of March 2016 TF Microfinance Bank was incorporated with Corporate Affairs Commission Nigeria, and by March 27, 2017 it was granted a final license to operate as a Unit Microfinance Bank. Our aim is to render niche financial services that is focused on the underbanked and unbanked populace. <br />
          <br />
          The pioneer, Efeurhobo Otaloghene, a graduate of Accounting from Obafemi Awolowo University and a Master degree holder in Finance and Investment from the University of Edinburgh, United Kingdom, had developed a passion to assist the unemployed graduates and micro-scale business owners in Nigeria. He came up with a business model during his National Youth Service Corps (NYSC) period in Benue State, Nigeria. The business model focused on mobilizing corps members who agreed to set aside a certain percentage of their monthly stipends in a common pool and have the funds disbursed as micro loans to micro-scale business owners in Benue State. This project was successful and metamorphosed into a Community Development Service (CDS) group which earned him a Commendation Letter from the State Coordinator of the NYSC. {" "}
          <br />
          <br />
          The drive to build on this business model, make it a tool of solving unemployment problem and empowering the unbanked and underbanked in Nigeria was the reason he travelled abroad to broaden and deepen his business, financial and investment management knowledge and skills. This was what led to the incorporation and registration of TF Microfinance Bank
        </p>
        <div className={styles.StatsWrap}>
            <div className={styles.Stat}>
                <p>Licensed in</p>
                <h3>2017</h3>
            </div>
            <div className={styles.Stat}>
                <p>Currently banked</p>
                <h3>16,000+</h3>
                <p>Customers</p>
            </div>
            <div className={styles.Stat}>
                <p>Over</p>
                <h3>1 Billion</h3>
                <p>Disbursed as Loans</p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
