import styles from "./Snackbar.module.css";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import nairaIcon from "../../../../assets/icons/naira.png";
import { Link } from "react-router-dom";
import { TextButton } from "../Button";

export default function BreadCrumb(props) {
  const {loanType = ''} = props
  const linkText = (loanType.toLowerCase()).split(' ')
  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.vertical || "bottom",
        horizontal: "center",
      }}
      open={props.open}
      autoHideDuration={props.vertical ? 10000 : 6000}
      onClose={props.handleClose}
      message={props.message}
    >
      <div
        style={{
          boxShadow: props.success
            ? "0px 20px 40px rgba(22, 22, 22, 0.1), inset 0px 5px 0px #42BE65"
            : "0px 20px 40px rgba(22, 22, 22, 0.1), inset 0px 5px 0px #0f62fe",
        }}
        className={styles.BreadCrumb}
      >
        {props.loanType ? (
          <p className={styles.message}>
            <span>
              {`Total amount to be repaid on chosen amount on ${props.loanType} across ${props.duration} months will be:`}{" "}
              <br />
              <br />
              <Link style={{ paddingTop: "20px" }} to={`/loans/${linkText.join('-')}`}>
                <TextButton>Apply for this loan</TextButton>
              </Link>
            </span>
            <img src={nairaIcon} alt="" />
            <span> {props.message}</span>{" "}
          </p>
        ) : (
          props.message
        )}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={props.handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </Snackbar>
  );
}
