import { Grid } from '@mui/material';
import styles from './OurTeam.module.css';
import TeamCard from './TeamCard/TeamCard';

const OurTeam = () => {
    return (
        <div id="board" className={styles.OurTeam}>
            <h3>Our Board of Directors</h3>
            <div className={styles.Wrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="manager"/>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="chairman"/>
                    </Grid> */}
                    <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="non-executive"/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="non-executive-2"/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="non-executive-3"/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="non-executive-4"/>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} lg={4}>
                        <TeamCard boardMember="non-executive-5"/>
                    </Grid> */}
                </Grid>
            </div>
        </div>
    )
}

export default OurTeam
