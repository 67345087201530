import React from 'react';
import Layout from '../../hoc/Layout';
// import CallToAct from '../../UI/molecules/CallToAct/CallToAct';
import HeroSection from '../../UI/organisms/HeroSection/HeroSection';
import MobileBanking from '../../UI/organisms/MobileBanking/MobileBanking';
import OurBlog from '../../UI/organisms/OurBlog/OurBlog';
import OurMission from '../../UI/organisms/OurMission/OurMission';
import OurServices from '../../UI/organisms/OurServices/OurServices';
import Testimonials from '../../UI/organisms/Testimonials/Testimonials';
import styles from './Home.module.css';

function Home() {
    return (
        <Layout>
        <div className={styles.Home}>
            <HeroSection/>
            <OurMission />
            <OurServices />
            <OurBlog />
            <MobileBanking/>
            <Testimonials />
        </div>
        </Layout>
    )
}

export default Home
