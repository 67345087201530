import styles from './LoanApplication.module.css'
import LoanProcess from './LoanProcess/LoanProcess'

const LoanApplication = () => {
    return (
        <div className={styles.LoanApplication}>
             <h2>Loan Application Process</h2>
             <LoanProcess numberOfSteps={3} />
        </div>
    )
}

export default LoanApplication
