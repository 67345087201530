import styles from "./FormProgress.module.css";

function FormProgress(props) {
  return (
    <div className={styles.FormProgress}>
      <div
        onClick={() => props.handleSteps("first")}
        style={{
          background: props.formStep === "personal-info" ? "#000" : null,
        }}
        className={styles.ProgressBar}
      ></div>
      <div
        onClick={() => props.handleSteps("second")}
        style={{
          background: props.formStep === "residential-info" ? "#000" : null,
        }}
        className={styles.ProgressBar}
      ></div>
      <div
        onClick={() => props.handleSteps("third")}
        style={{
          background: props.formStep === "business-info" ? "#000" : null,
        }}
        className={styles.ProgressBar}
      ></div>
      <div
        onClick={() => props.handleSteps("fourth")}
        style={{ background: props.formStep === "documents" ? "#000" : null }}
        className={styles.ProgressBar}
      ></div>
      <div
        onClick={() => props.handleSteps("fifth")}
        style={{ background: props.formStep === "loan-info" ? "#000" : null }}
        className={styles.ProgressBar}
      ></div>
    </div>
  );
}

export default FormProgress;
