import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "../../atoms/Button";
import {
  FileInput,
  LoanInput,
  SelectDate,
  SelectInput,
} from "../../atoms/Input/Input";
import Logo from "../../atoms/Logo/Logo";
import FormProgress from "./FormProgress/FormProgress";
import styles from "./FormSection.module.css";
import BreadCrumb from "../../atoms/Snackbar/Snackbar";
import nairaIcon from "../../../../assets/icons/newNaira.png";
import { Link } from "react-router-dom";

function FormSection() {
  const [formType, setFormType] = useState("personal-info");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [passport, setPassport] = useState(null);
  const [currentBill, setCurrentBill] = useState(null);
  const [validID, setValidID] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  let formPrompt = (
    <p>
      Please, carefull fill this form to apply for a loan. Once done, click{" "}
      <span>'finish'</span> and we will reach out to you. Please do not refresh
      this page.
    </p>
  );
  const handleBillFileChange = (e) => {
    setCurrentBill(e.target.files[0]);
    if (e.target.files[0].size > 1048576 * 2) {
      setFileError(true);
    } else {
      setFileError(false);
    }
  };
  const handleIDFileChange = (e) => {
    setValidID(e.target.files[0]);
    if (e.target.files[0].size > 1048576 * 2) {
      setFileError(true);
    } else {
      setFileError(false);
    }
  };
  const handlePassPortFileChange = (e) => {
    setPassport(e.target.files[0]);
    if (e.target.files[0].size > 1048576 * 2) {
      setFileError(true);
    } else {
      setFileError(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(true);
  }, []);
  const [date, setDate] = useState({
    dateOfBirth: null,
    livingSinceWhen: null,
    ageOfBusiness: null,
  });

  const handleDateChange = (value) => {
    setDate({ ...date, dateOfBirth: value });
  };
  const dateChangeHandler = (value) => {
    setDate({ ...date, livingSinceWhen: value });
  };
  const changeDateHandler = (value) => {
    setDate({ ...date, ageOfBusiness: value });
  };

  const selectOptions = {
    gender: ["Male", "Female", "Non-binary"],
    maritalStatus: ["Married", "Widowed", "Divorced", "Seperated", "Single"],
    educationLevel: ["Graduate", "Technical", "Secondary", "Primary", "None"],
    howDidYouKnowAboutTF: [
      "Recommendation",
      "Advertisement",
      "Loan Officer Visit",
      "Brochures",
      "Other",
    ],
    typeOfHouse: ["Rented", "Family", "Mortgaged", "Own House", "Others"],
    businessType: [
      "Sole Proprietorship",
      "Enterprise",
      "Patnership",
      "Cooperate",
    ],
    economicSector: ["Trade/Retail", "Service", "Manufacturing", "Mixed"],
    typeOfEstablishment: [
      "Market",
      "ShoppingComplex",
      "Regular Temporary Market",
      "Association",
      "Independent Establishment",
    ],
    loanType: [
      "Consumer Loan",
      "Business Loan",
      "Education Loan",
      "Asset Lease Loan",
    ],
    duration: [3, 6, 9, 12, 15, 18, 21, 24, "Longer than 24"],
  };

  const initialValues = {
    firstname: "",
    othernames: "",
    email: "",
    nationality: "",
    stateOfOrigin: "",
    gender: "",
    maritalStatus: "",
    educationLevel: "",
    occupation: "",
    howDidYouKnowAboutTF: "",
    address: "",
    country: "",
    state: "",
    landmark: "",
    typeOfHouse: "",
    phone: "",
    businessName: "",
    businessType: "",
    economicSector: "",
    typeOfEstablishment: "",
    businessAddress: "",
    businessPhone: "",
    religion: "",
    loanAmount: localStorage.getItem("loanAmount"),
    loanType: localStorage.getItem("loanType"),
    loanTenure: localStorage.getItem("loanTenure"),
    bvn: "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("This is a required field"),
    email: Yup.string()
      .email("invalid email format")
      .required("This is a required field"),
    othernames: Yup.string().required("This is a required field"),
    nationality: Yup.string().required("This is a required field"),
    stateOfOrigin: Yup.string().required("This is a required field"),
    gender: Yup.string().required("This is a required field"),
    maritalStatus: Yup.string().required("This is a required field"),
    educationLevel: Yup.string().required("This is a required field"),
    occupation: Yup.string().required("This is a required field"),
    religion: Yup.string().required("This is a required field"),
    howDidYouKnowAboutTF: Yup.string().required("This is a required field"),
    address: Yup.string().required("This is a required field"),
    country: Yup.string().required("This is a required field"),
    state: Yup.string().required("This is a required field"),
    landmark: Yup.string().required("This is a required field"),
    typeOfHouse: Yup.string().required("This is a required field"),
    phone: Yup.string()
      .matches(/^([0]{1})[0-9]{10}$/, "Phone number is not valid")
      .required("This is a required field"),
    businessName: Yup.string().required("This is a required field"),
    businessType: Yup.string().required("This is a required field"),
    economicSector: Yup.string().required("This is a required field"),
    typeOfEstablishment: Yup.string().required("This is a required field"),
    businessAddress: Yup.string().required("This is a required field"),
    businessPhone: Yup.string().required("This is a required field"),
    loanAmount: Yup.number().required("This is a required field"),
    loanTenure: Yup.number().required("This is a required field"),
    loanType: Yup.string().required("This is a required field"),
    bvn: Yup.number().required("Please enter a valid number"),
  });

  const onSubmit = (values) => {
    if (!date.ageOfBusiness || !date.dateOfBirth || !date.livingSinceWhen) {
      setError(
        "Please enter your 'date of birth'/ 'age of business' /' living since when'"
      );
      return;
    }
    if (!passport || !validID || !currentBill) {
      setError("Please upload your passport/valid id/current bill");
      setFormType("documents")
      return;
    }
    if (fileError) {
      setFileError(true);
      setFormType("documents")
      return;
    }
    setLoading(true);
    // console.log({...values, loanTenure: `${values.loanTenure} Months`, loanAmount: `#${(values.loanAmount).toLocaleString()}`})
    const formData = new FormData();
    formData.append("passport", passport);
    formData.append("validID", validID);
    formData.append("currentBill", currentBill);
    formData.append("email", values.email);
    formData.append("firstname", values.firstname);
    formData.append("othernames", values.othernames);
    formData.append("nationality", values.nationality);
    formData.append("stateOfOrigin", values.stateOfOrigin);
    formData.append("gender", values.gender);
    formData.append("maritalStatus", values.maritalStatus);
    formData.append("educationLevel", values.educationLevel);
    formData.append("occupation", values.occupation);
    formData.append("religion", values.religion);
    formData.append("howDidYouKnowAboutTF", values.howDidYouKnowAboutTF);
    formData.append("address", values.address);
    formData.append("country", values.country);
    formData.append("state", values.state);
    formData.append("landmark", values.landmark);
    formData.append("typeOfHouse", values.typeOfHouse);
    formData.append("phone", values.phone);
    formData.append("businessName", values.businessName);
    formData.append("businessType", values.businessType);
    formData.append("economicSector", values.economicSector);
    formData.append("typeOfEstablishment", values.typeOfEstablishment);
    formData.append("businessAddress", values.businessAddress);
    formData.append("businessPhone", values.businessPhone);
    formData.append(
      "loanAmount",
      `#${parseInt(values.loanAmount).toLocaleString()}`
    );
    formData.append("loanTenure", `${values.loanTenure} Months`);
    formData.append("loanType", values.loanType);
    formData.append("bvn", values.bvn);
    // for (const key in values) {
    //   formData.append(`${key}`, values[key]);
    // }
    // for (const key in date) {
    //   formData.append(`${key}`, date[key]);
    // }
    formData.append("dateOfBirth", date.dateOfBirth);
    formData.append("livingSinceWhen", date.livingSinceWhen);
    formData.append("ageOfBusiness", date.ageOfBusiness);
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    // myHeaders.append("Accept", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var requestOptions = {
      method: "POST",
      body: formData,
      headers: myHeaders,
    };

    fetch(
      "https://thinkfinancemfb.herokuapp.com/api/loan/apply",
      requestOptions
    )
      .then((result) => {
        setLoading(false);
        setOpen(true);
        setSuccess(true);
        setError(null);
        if (!result.ok) {
          setError(result.statusText);
          setSuccess(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError(error);
        setSuccess(false)
      });
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  useEffect(() => {
    for (const error in formik.errors) {
      if (formik.errors[error] && formik.isSubmitting) {
        setError("seems like you missed something");
      }
    }
  }, [formik.errors, formik.isSubmitting])

  const formStepHandler = (step) => {
    if (step === "first") {
      setFormType("personal-info");
    } else if (step === "second") {
      setFormType("residential-info");
    } else if (step === "third") {
      setFormType("business-info");
    } else if (step === "fourth") {
      setFormType("documents");
    } else if (step === "fifth") {
      setFormType("loan-info");
    }
  };
  let formTitle;
  let formInfo;

  switch (formType) {
    case "personal-info":
      formTitle = "Personal Information";
      formInfo =
        "We'd love to know more about you. Please enter the necessary information at where appropriate.";
      break;
    case "residential-info":
      formTitle = "Residential Information";
      formInfo =
        "It is necessary that we have access to your residential information. Please enter the necessary information at where appropriate";
      break;
    case "business-info":
      formTitle = "Business Information";
      formInfo =
        "We'd love to understand the nature of your business. Please enter the necessary information at where appropriate";
      break;
    case "documents":
      formTitle = "Documents Upload";
      formInfo =
        "Please upload the appropriate document as specified. Please, file size should not exceed 2MB.";
      break;
    case "loan-info":
      formTitle = "Loan Information";
      formInfo =
        "The final step! Please enter the necessary information where appropriate.";
      break;
    default:
      return null;
  }

  return (
    <form
      encType="multipart/form-data"
      data-netlify="true"
      onSubmit={formik.handleSubmit}
      className={styles.FormSection}
    >
      <BreadCrumb
        success={success}
        open={open}
        vertical="top"
        handleClose={handleClose}
        message={
          success ? (
            <p style={{ fontWeight: "500" }}>
              You have successfully submitted an application for a loan! Our
              team will get across to you in no time.
            </p>
          ) : (
            formPrompt
          )
        }
      />
      <Logo />
      <FormProgress handleSteps={formStepHandler} formStep={formType} />

      <div className={styles.FormDetails}>
        <h2>{formTitle}</h2>
        <p>{formInfo}</p>
      </div>
      <div className={styles.FormWrap}>
        <div
          style={{
            zIndex: formType === "personal-info" ? 10 : -1,
            transform: formType === "personal-info" ? "scale(1)" : "scale(0)",
          }}
          className={[styles.FormGroup, styles.BusinessInfo].join(" ")}
        >
          <div className={styles.InputWrap}>
            <LoanInput
              type="text"
              name="firstname"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              placeholder="First Name"
              onBlur={formik.handleBlur}
              error={formik.touched.firstname && formik.errors.firstname}
              helper={
                formik.touched.firstname && formik.errors.firstname
                  ? formik.errors.firstname
                  : null
              }
            />
            <LoanInput
              type="text"
              name="othernames"
              value={formik.values.othernames}
              onChange={formik.handleChange}
              placeholder="Middle Name & Last Name"
              onBlur={formik.handleBlur}
              error={formik.touched.othernames && formik.errors.othernames}
              helper={
                formik.touched.othernames && formik.errors.othernames
                  ? formik.errors.othernames
                  : null
              }
            />
          </div>
          <div className={styles.InputWrap}>
            <LoanInput
              type="text"
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              placeholder="Nationality"
              onBlur={formik.handleBlur}
              error={formik.touched.nationality && formik.errors.nationality}
              helper={
                formik.touched.nationality && formik.errors.nationality
                  ? formik.errors.nationality
                  : "e.g: 'Nigerian', or 'Ghanaian'"
              }
            />
            <LoanInput
              type="text"
              name="stateOfOrigin"
              value={formik.values.stateOfOrigin}
              onChange={formik.handleChange}
              placeholder="State of Origin"
              onBlur={formik.handleBlur}
              error={
                formik.touched.stateOfOrigin && formik.errors.stateOfOrigin
              }
              helper={
                formik.touched.stateOfOrigin && formik.errors.stateOfOrigin
                  ? formik.errors.stateOfOrigin
                  : null
              }
            />
          </div>
          <LoanInput
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Email"
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            helper={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
          <SelectDate
            value={date.dateOfBirth}
            placeholder="Date of Birth (required)"
            name="dateOfBirth"
            handleChange={handleDateChange}
            helper="required"
          />
          <div style={{ display: "flex" }}>
            <div className={styles.SelectWrap}>
              <SelectInput
                value={formik.values.gender}
                placeholder="Gender"
                selectType="gender"
                options={selectOptions.gender}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.gender && formik.errors.gender}
                helper={
                  formik.touched.gender && formik.errors.gender
                    ? formik.errors.gender
                    : null
                }
              />
            </div>
            <div className={styles.SelectWrap}>
              <SelectInput
                value={formik.values.maritalStatus}
                placeholder="Marital Status"
                selectType="maritalStatus"
                handleChange={formik.handleChange}
                options={selectOptions.maritalStatus}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.maritalStatus && formik.errors.maritalStatus
                }
                helper={
                  formik.touched.maritalStatus && formik.errors.maritalStatus
                    ? formik.errors.maritalStatus
                    : null
                }
              />
            </div>
          </div>
          <SelectInput
            value={formik.values.educationLevel}
            placeholder="Education Level"
            selectType="educationLevel"
            options={selectOptions.educationLevel}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.educationLevel && formik.errors.educationLevel
            }
            helper={
              formik.touched.educationLevel && formik.errors.educationLevel
                ? formik.errors.educationLevel
                : null
            }
          />
          <LoanInput
            onChange={formik.handleChange}
            value={formik.values.occupation}
            placeholder="Occupation"
            name="occupation"
            onBlur={formik.handleBlur}
            error={formik.touched.occupation && formik.errors.occupation}
            helper={
              formik.touched.occupation && formik.errors.occupation
                ? formik.errors.occupation
                : null
            }
          />
          <LoanInput
            onChange={formik.handleChange}
            value={formik.values.religion}
            placeholder="Religion"
            name="religion"
            onBlur={formik.handleBlur}
            error={formik.touched.religion && formik.errors.religion}
            helper={
              formik.touched.religion && formik.errors.religion
                ? formik.errors.religion
                : null
            }
          />
          <SelectInput
            value={formik.values.howDidYouKnowAboutTF}
            options={selectOptions.howDidYouKnowAboutTF}
            placeholder="How Did You Know About Think Finance?"
            handleChange={formik.handleChange}
            selectType="howDidYouKnowAboutTF"
            onBlur={formik.handleBlur}
            error={
              formik.touched.howDidYouKnowAboutTF &&
              formik.errors.howDidYouKnowAboutTF
            }
            helper={
              formik.touched.howDidYouKnowAboutTF &&
              formik.errors.howDidYouKnowAboutTF
                ? formik.errors.howDidYouKnowAboutTF
                : null
            }
          />
          <Button
            type="button"
            clicked={() => {
              window.scrollTo(0, 0);
              setFormType("residential-info");
            }}
          >
            Residential Information
          </Button>
        </div>

        <div
          style={{
            zIndex: formType === "residential-info" ? 10 : -1,
            transform:
              formType === "residential-info" ? "scale(1)" : "scale(0)",
          }}
          className={[styles.FormGroup, styles.PersonalInfo].join(" ")}
        >
          <LoanInput
            type="text"
            name="address"
            placeholder="Full Residential Address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.address && formik.errors.address}
            helper={
              formik.touched.address && formik.errors.address
                ? formik.errors.address
                : null
            }
          />

          <div className={styles.InputWrap}>
            <LoanInput
              type="text"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              placeholder="Country"
              onBlur={formik.handleBlur}
              error={formik.touched.country && formik.errors.country}
              helper={
                formik.touched.country && formik.errors.country
                  ? formik.errors.country
                  : null
              }
            />
            <LoanInput
              type="text"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              placeholder="State"
              onBlur={formik.handleBlur}
              error={formik.touched.state && formik.errors.state}
              helper={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : null
              }
            />
          </div>
          <LoanInput
            type="text"
            value={formik.values.landmark}
            onChange={formik.handleChange}
            name="landmark"
            placeholder="Landmark"
            onBlur={formik.handleBlur}
            error={formik.touched.landmark && formik.errors.landmark}
            helper={
              formik.touched.landmark && formik.errors.landmark
                ? formik.errors.landmark
                : "a building or an object that helps you identify your address"
            }
          />
          <SelectInput
            value={formik.values.typeOfHouse}
            placeholder="Type of House"
            handleChange={formik.handleChange}
            selectType="typeOfHouse"
            options={selectOptions.typeOfHouse}
            onBlur={formik.handleBlur}
            error={formik.touched.typeOfHouse && formik.errors.typeOfHouse}
            helper={
              formik.touched.typeOfHouse && formik.errors.typeOfHouse
                ? formik.errors.typeOfHouse
                : null
            }
          />
          <div style={{ display: "flex" }}>
            <SelectDate
              style={{ marginRight: "3%", width: "48.5%" }}
              value={date.livingSinceWhen}
              type="month-year"
              placeholder="Living Since When? (required)"
              handleChange={dateChangeHandler}
              helper="required"
            />
            <LoanInput
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
              type="tel"
              pattern="[0-9]{4}-[0-9]{3}-[0-9]{4}"
              placeholder="Phone Number"
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
              helper={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : "e.g: 080x-xxx-xxxx"
              }
            />
          </div>
          <Button
            type="button"
            clicked={() => {
              window.scrollTo(0, 0);
              setFormType("business-info");
            }}
          >
            Business Information
          </Button>
        </div>

        <div
          style={{
            zIndex: formType === "business-info" ? 10 : -1,
            transform: formType === "business-info" ? "scale(1)" : "scale(0)",
          }}
          className={[styles.FormGroup, styles.BusinessInfo].join(" ")}
        >
          <LoanInput
            type="text"
            name="businessName"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            placeholder="Name of Business"
            onBlur={formik.handleBlur}
            error={formik.touched.businessName && formik.errors.businessName}
            helper={
              formik.touched.businessName && formik.errors.businessName
                ? formik.errors.businessName
                : null
            }
          />
          <div style={{ display: "flex" }}>
            <div className={styles.SelectWrap}>
              <SelectInput
                value={formik.values.businessType}
                options={selectOptions.businessType}
                placeholder="Business Type"
                handleChange={formik.handleChange}
                selectType="businessType"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.businessType && formik.errors.businessType
                }
                helper={
                  formik.touched.businessType && formik.errors.businessType
                    ? formik.errors.businessType
                    : null
                }
              />
            </div>
            <div className={styles.SelectWrap}>
              <SelectInput
                value={formik.values.economicSector}
                options={selectOptions.economicSector}
                placeholder="Economic Sector"
                handleChange={formik.handleChange}
                selectType="economicSector"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.economicSector && formik.errors.economicSector
                }
                helper={
                  formik.touched.economicSector && formik.errors.economicSector
                    ? formik.errors.economicSector
                    : null
                }
              />
            </div>
          </div>
          <SelectInput
            value={formik.values.typeOfEstablishment}
            options={selectOptions.typeOfEstablishment}
            placeholder="Type of Establishment"
            handleChange={formik.handleChange}
            selectType="typeOfEstablishment"
            onBlur={formik.handleBlur}
            error={
              formik.touched.typeOfEstablishment &&
              formik.errors.typeOfEstablishment
            }
            helper={
              formik.touched.typeOfEstablishment &&
              formik.errors.typeOfEstablishment
                ? formik.errors.typeOfEstablishment
                : null
            }
          />
          <LoanInput
            type="text"
            value={formik.values.businessAddress}
            onChange={formik.handleChange}
            name="businessAddress"
            placeholder="Business Full Address"
            onBlur={formik.handleBlur}
            error={
              formik.touched.businessAddress && formik.errors.businessAddress
            }
            helper={
              formik.touched.businessAddress && formik.errors.businessAddress
                ? formik.errors.businessAddress
                : null
            }
          />

          <SelectDate
            value={date.ageOfBusiness}
            type="month-year"
            placeholder="Age of Business (required)"
            handleChange={changeDateHandler}
            helper="required"
          />
          <LoanInput
            name="businessPhone"
            type="tel"
            value={formik.values.businessPhone}
            onChange={formik.handleChange}
            pattern="[0-9]{4}-[0-9]{3}-[0-9]{4}"
            placeholder="Business Phone Number"
            onBlur={formik.handleBlur}
            error={formik.touched.businessPhone && formik.errors.businessPhone}
            helper={
              formik.touched.businessPhone && formik.errors.businessPhone
                ? formik.errors.businessPhone
                : null
            }
          />
          <Button
            type="button"
            clicked={() => {
              window.scrollTo(0, 0);
              setFormType("documents");
            }}
          >
            Documents Upload
          </Button>
        </div>

        <div
          style={{
            zIndex: formType === "documents" ? 10 : -1,
            transform: formType === "documents" ? "scale(1)" : "scale(0)",
          }}
          className={[styles.FormGroup, styles.BusinessInfo].join(" ")}
        >
          <FileInput
            name="passport"
            id="passport"
            onChange={(e) => handlePassPortFileChange(e)}
            helper={
              fileError
                ? "Please upload a valid document. Remember, file size cannot exceed 2MB"
                : "Passport (white background)"
            }
            error={fileError}
          />
          <FileInput
            name="validID"
            id="validID"
            onChange={(e) => handleIDFileChange(e)}
            helper={
              fileError
                ? "Please upload a valid document. Remember, file size cannot exceed 2MB"
                : " Valid means of Identification (any government approved ID - could be International Passport, driving license, national identity card, or permanent voter card)"
            }
            error={fileError}
          />
          <FileInput
            name="currentBill"
            id="currentBill"
            onChange={(e) => handleBillFileChange(e)}
            helper={
              fileError
                ? "Please upload a valid document. Remember, file size cannot exceed 2MB"
                : "Current NEPA bill (at least 1 month prior)"
            }
            error={fileError}
          />
          <Button clicked={() => setFormType("loan-info")}>
            Loan Information
          </Button>
        </div>
        <div
          style={{
            zIndex: formType === "loan-info" ? 10 : -1,
            transform: formType === "loan-info" ? "scale(1)" : "scale(0)",
          }}
          className={[styles.FormGroup, styles.BusinessInfo].join(" ")}
        >
          <LoanInput
            name="loanAmount"
            type="number"
            value={formik.values.loanAmount}
            onChange={formik.handleChange}
            placeholder="Enter Loan Amount"
            onBlur={formik.handleBlur}
            error={formik.touched.loanAmount && formik.errors.loanAmount}
            helper={
              formik.touched.loanAmount && formik.errors.loanAmount ? (
                formik.errors.loanAmount
              ) : (
                <span style={{ display: "flex", alignItems: "center" }}>
                  Your loan amount will be
                  <img
                    style={{
                      height: "13px",
                      width: "13px",
                      marginLeft: "10px",
                    }}
                    src={nairaIcon}
                    alt=""
                  />{" "}
                  {Math.ceil(formik.values.loanAmount).toLocaleString()}
                </span>
              )
            }
          />
          <div style={{ display: "flex" }}>
            <div className={styles.SelectWrap}>
              <SelectInput
                value={formik.values.loanTenure}
                options={selectOptions.duration}
                placeholder="Loan tenure"
                handleChange={formik.handleChange}
                selectType="loanTenure"
                onBlur={formik.handleBlur}
                error={formik.touched.loanTenure && formik.errors.loanTenure}
                helper={
                  formik.touched.loanTenure && formik.errors.loanTenure
                    ? formik.errors.loanTenure
                    : "For how long should the loan cover?"
                }
              />
            </div>
            <div className={styles.SelectWrap}>
              <SelectInput
                value={formik.values.loanType}
                options={selectOptions.loanType}
                placeholder="Loan type"
                handleChange={formik.handleChange}
                selectType="loanType"
                onBlur={formik.handleBlur}
                error={formik.touched.loanType && formik.errors.loanType}
                helper={
                  formik.touched.loanType && formik.errors.loanType
                    ? formik.errors.loanType
                    : null
                }
              />
            </div>
          </div>
          <LoanInput
            name="bvn"
            type="number"
            value={formik.values.bvn}
            onChange={formik.handleChange}
            placeholder="Enter BVN"
            onBlur={formik.handleBlur}
            error={formik.touched.bvn && formik.errors.bvn}
            helper={
              formik.touched.bvn && formik.errors.bvn ? (
                formik.errors.bvn
              ) : (
                <>
                  Your BVN is only an identifier that we use to identify and
                  verify you. Check out our{" "}
                  <Link style={{ color: "blue" }} to="/privacy-policy">
                    privacy policy
                  </Link>
                  .
                </>
              )
            }
          />
          <Button
            type="submit"
            disabled={fileError || loading || success}
            clicked={formik.handleSubmit}
          >
            {loading ? "Processing..." : "Finish"}
          </Button>
          {error ? (
            <p
              style={{ color: "red", paddingTop: "20px", textAlign: "center" }}
            >
              {typeof error === "object" ? "There was an error" : error}
            </p>
          ) : null}
          {success ? (
            <p
              style={{ color: "green", paddingTop: "20px", textAlign: "center" }}
            >
              Successfully submitted
            </p>
          ) : null}
        </div>
      </div>
    </form>
  );
}

export default FormSection;
