import Layout from '../../hoc/Layout'
import LoanDescription from '../../UI/organisms/LoanDescription/LoanDescription'
import TopSection from '../../UI/organisms/LoanDetailsTopSection/TopSection'
import OtherLoans from '../../UI/organisms/OtherLoans/OtherLoans'
import styles from './LoanDetails.module.css'

const ConsumerLoanDetails = () => {
    const description = <p>
    You need access to loan to pay house rent, children school fees and fix broken down car or buy a household gadget.
  </p>
    const requirements = <>
    <li>Must be a salary earner.</li>
    <li>Currently working with a company for at least a year.</li>
    <li>Monthly repayment must not exceed 33% of net income.</li>
    </>
    
    const features = <>
    <li>Convenient for all strata of salary earners.</li>
    <li>Staff must have been in the company for a minimum of One year.</li>
    <li>Monthly repayment must not exceed Thirty-Three percent of Net Pay.</li>
    <li>Competitive Interest rate.</li>
    <li>Flexible repayment plan.</li>
    </>
    const otherLoans = ['business', 'asset-lease', 'education'];

    return (
        <Layout>
        <div className={styles.LoanDetails}>
            <TopSection title="Consumer Loan" />
            <LoanDescription loanType="consumer" features={features} requirements={requirements} description={description} />
            <OtherLoans otherLoans={otherLoans} />
        </div>
        </Layout>
    )
}

export default ConsumerLoanDetails
