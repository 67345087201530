import { Link } from 'react-router-dom';
import { Button } from '../../atoms/Button';
import HeroForm from '../HeroForm/HeroForm';
import styles from './HeroSection.module.css';

const HeroSection = () => {
    return (
      <div className={styles.HeroSection}>
        <div className={styles.Container}>
          <div className={styles.Headline}>
            <h1>
              Quick and Easy Loans
              <br /> up to 5 Million Naira
            </h1>
            <ul>
              <li>Trusted by 6000+ Customers</li>
              <li>Flexible Repayment</li>
            </ul>
            <div className={styles.ButtonWrap}>
              <Link to="/loans/">
                {" "}
                <span>
                  <Button noBorderRadius>Apply for a Loan</Button>
                </span>
              </Link>
            </div>
          </div>
          <HeroForm />
        </div>
      </div>
    );
}

export default HeroSection