import NavLinks from '../NavLinks/NavLinks';
import styles from './MobileMenu.module.css';
import cross from '../../../../assets/icons/cross.svg';


const MobileMenu = ({menuState, handleMenu}) => {
    return (
        <div style={{transform: menuState? 'translateX(0)': 'translateX(100%)' }} className={styles.MobileMenu}>
            <span onClick={handleMenu}><img src={cross} alt="" /></span>
            <div className={styles.Wrapper}>
            <NavLinks />
            </div>
        </div>
    )
}

export default MobileMenu
