import Card from '../../molecules/Card/Card'
import styles from './OtherLoans.module.css'
import {Grid} from '@mui/material';

const OtherLoans = (props) => {
    return (
        <div className={styles.OtherLoans}>
            <h3 className={styles.Title}>Other Loans</h3>
            <div className={styles.Wrapper}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Card loanType={props.otherLoans[0]}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Card color="green" loanType={props.otherLoans[1]}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Card loanType={props.otherLoans[2]} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default OtherLoans
