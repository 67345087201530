import { useReducer, useState } from "react";
import { Button } from "../../atoms/Button";
import {
  FormInput,
  FormSelectInput,
} from "../../molecules/FormInput/FormInput";
import styles from "./HeroForm.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "../../atoms/Snackbar/Snackbar";
import { Link } from "react-router-dom";

function reducer(state, action) {
  const multiple = action.payload.duration;
  switch (action.type) {
    case "Consumer Loan":
      return (
        action.payload.amount + action.payload.amount * ((4 * multiple) / 100)
      );
    case "Business Loan":
      return (
        action.payload.amount + action.payload.amount * ((6.5 * multiple) / 100)
      );
    case "Education Loan":
      return (
        action.payload.amount +
        (action.payload.amount * (3.333333333 * multiple)) / 100
      );

    default:
      return state;
  }
}

const HeroForm = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const selectOptions = {
    loanType: ["Consumer Loan", "Business Loan", "Education Loan"],
    duration: [3, 6, 9, 12, 15, 18, 21, 24],
  };
  const initialValues = {
    amount: "",
    loanType: "",
    duration: "",
  };
  const validationSchema = Yup.object({
    amount: Yup.number().required("This is a required field")
    .positive().min(20000, 'Input a higher amount'),
    loanType: Yup.string().required("This is a required field"),
    duration: Yup.number().required("This is a required field"),
  });
  const onSubmit = (values) => {
    const {amount, duration, loanType} = values
    dispatch({
      type: loanType,
      payload: { amount: amount, duration: duration },
    });
    setOpen(true);
    localStorage.setItem('loanAmount', amount)
    localStorage.setItem('loanType', loanType)
    localStorage.setItem('loanTenure', duration)
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [result, dispatch] = useReducer(reducer, 0);
  const durationInfo = (
    <span>
      Loan repayment plans are different and depends entirely on the type of
      loan.{" "}
      <Link style={{ color: "blue" }} to="/loans">
        Find out more about the different loans here
      </Link>
    </span>
  );
  const serviceStyles = {
    height: props.activePage === 'services' ? '670px' : 'fit-content',
    marginTop: props.activePage === 'services' ? '40px' : 0,
    marginBottom: props.activePage === 'services' ? '50px' : 0
  }

  return (
    <>
      <BreadCrumb
        open={open}
        handleClose={handleClose}
        loanType={formik.values.loanType}
        duration={formik.values.duration}
        message={Math.ceil(result).toLocaleString()}
      />
      <div style={serviceStyles} className={styles.HeroForm}>
        <div className={styles.FormHeader}>
          <h1>How much do you want</h1>
          <p>Enter your preferred loan specifications below</p>
        </div>
        <div id={styles.Wrap}>
          <FormInput
            name="amount"
            value={formik.values.amount}
            label="amount (naira)"
            placeholder="Enter Amount here"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && formik.errors.amount}
            helper={
              formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : null
            }
          />
        </div>
        <div id={styles.Wrap}>
          <FormSelectInput
            value={formik.values.loanType}
            handleChange={formik.handleChange}
            options={selectOptions.loanType}
            label="loan type"
            placeholder="Select Loan Type"
            selectType="loanType"
            error={formik.touched.loanType && formik.errors.loanType}
            helper={
              formik.touched.loanType && formik.errors.loanType
                ? formik.errors.loanType
                : null
            }
          />
        </div>
        <div id={styles.Wrap}>
          <FormSelectInput
            value={formik.values.duration}
            handleChange={formik.handleChange}
            options={selectOptions.duration}
            label="duration"
            placeholder="Select Duration"
            selectType="duration"
            error={formik.touched.duration && formik.errors.duration}
            helper={
              formik.touched.duration && formik.errors.duration
                ? formik.errors.duration
                : durationInfo
            }
            duration
          />
        </div>
        <div id={styles.Wrap}>
          <Button clicked={formik.handleSubmit} type="submit">
            Calculate
          </Button>
        </div>
      </div>
    </>
  );
};

export default HeroForm;
