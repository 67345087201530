import { Grid } from "@mui/material";
import styles from "./Testimonials.module.css";

const Testimonials = () => {
  return (
    <div className={styles.Testimonials}>
      <h5>Testimonials</h5>
      <h2>
        Here’s what people are <span>saying</span>
      </h2>
      <div className={styles.TestimonialWrap}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <div className={styles.TestimonialCard}>
              <h3>Alfred Emenike</h3>
              <p>
                I've worked with TF Microfinance Bank for over 7 years now, and
                during which myself and my workers have all gotten to save with
                them for one reason or the other. I started off with little
                savings, but now I can support my business with up to 5 million
                Naira credit from Think Finance.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <div className={styles.TestimonialCard}>
              <h3>Nathaniel Effiong</h3>
              <p>
                Amidst contemplations on dropping out of school due to being no
                longer able to pay the school fees, ThinkFinance Education loans
                enabled me pay my school fees for that year. I am now a
                graduate!
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <div className={styles.TestimonialCard}>
              <h3>Ayomide Gbolahan</h3>
              <p>
                Thin Finance Loan services helped me launch my business and even
                gave me advice that saved my business!
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Testimonials;
