import { Button } from "../../atoms/Button";
import styles from "./MobileBanking.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const MobileBanking = () => {
  return (
    <div className={styles.MobileBanking}>
      <h5>mobile banking</h5>
      <Carousel
        autoPlay
        infiniteLoop
        interval={10000}
        showThumbs={false}
        showStatus={false}
        swipeable={false}
        swipeScrollTolerance={3}
        preventMovementUntilSwipeScrollTolerance={true}
      >
        <div className={styles.Wrapper}>
          <h2>
            Go mobile, <span>bank with us anywhere, anytime!</span>
          </h2>
          <p>
            Access our services anywhere at anytime on your mobile phone with
            our mobile app <span>GoCash</span>
          </p>
          <div style={{ display: "none" }} className={styles.ButtonWrap}>
            <span>
              <Button>Primary Button</Button>
            </span>
            <span>
              <Button>Primary Button</Button>
            </span>
          </div>
          <a
              id={styles.googlePlayBadge}
              href="https://play.google.com/store/apps/details?id=com.appzone.android.bankonemobile.thinkfinancemfb&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
          <div className={styles.ComingSoon}>
            
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
          </div>
          
          </a>
        </div>
        <div className={styles.Wrapper}>
          <h2>
            We are set to launch our new product - <span>FinCorp ⚡</span>
          </h2>
          <p>
            This is an App specifically created for Youth Corps Members, which
            enables them to save, invest & apply for zero-interest loans, all on
            the same platform <span>with ease.</span>
          </p>
          <div style={{ display: "none" }} className={styles.ButtonWrap}>
            <span>
              <Button>Primary Button</Button>
            </span>
            <span>
              <Button>Primary Button</Button>
            </span>
          </div>
          <div className={styles.ComingSoon}>Coming Soon...</div>
        </div>
      </Carousel>
    </div>
  );
};

export default MobileBanking;
