import Layout from '../../hoc/Layout';
import styles from './TermsOfUse.module.css';

const TermsOfUse = () => {
  return (
    <Layout>
      <div className={styles.TermsOfUse}>
        <h1>Terms of Use Policy</h1>
        <h2>TF Microfinance Bank Website Terms of Use Agreement</h2>
        <p>
          Kindly read this Agreement carefully before visiting the TF
          Microfinance Bank Website “Hereinafter called “TF MFB”. By accessing
          the TF Microfinance Bank Website, the User agrees to be bound by the
          terms of this Agreement. If you do not wish to be bound by this
          agreement, do not access The TF Microfinance Bank Website.
          <br />
          <br />
          THIS AGREEMENT "Agreement" is entered into by and between TF
          Microfinance Bank Limited and any individual, corporation,
          association, agency, company, or other entity who accesses or uses the
          TF Microfinance Bank Limited Website.
          <br />
          <br />
          TF Microfinance Bank Website is a World Wide Web site on the Internet
          developed to communicate with users on the go. The Website is owned
          and operated solely by TF Microfinance Bank and contains information,
          communications, opinions, text, graphics, links, electronic art,
          animations, audio, video, software, photos, music, sounds and other
          material and data formatted, organized and collected in a variety of
          forms that are generally accessible to Users,
        </p>
        <h2>Accessing the TF MFB Website</h2>
        <p>
          By this agreement, users are responsible for providing all hardware,
          software, internet service charges, telephone or other communications
          equipment and/or service to connect to the Internet and access the TF
          MFB Website.
        </p>
        <h2>Conduct of the User </h2>
        <p>
          By this agreement, you agree to access and use the TF MFB Website only
          for lawful purposes. By accessing the TF MFB Website, you agree that
          you will not:
          <ul>
            <li>
              Post or transmit any unlawful, threatening, abusive, libelous,
              defamatory, obscene, vulgar, pornographic, profane, or indecent
              information of any kind, including without limitation any
              transmissions constituting or encouraging conduct that would
              constitute a criminal offence, give rise to civil liability or
              otherwise violate any local, state, national, or international
              law;
            </li>
            <li>
              Post or transmit any information, software, or other material
              which violates or infringes in the rights of others, including
              material which is an invasion of privacy or publicity rights or
              which is protected by copyright, trademark or other proprietary
              right, or derivative works with respect thereto, without first
              obtaining permission from the owner or right holder.
            </li>
            <li>
              Post or transmit any information, software or other material which
              contains a virus or other harmful component;
            </li>
            <li>
              Alter, damage or delete any Content or other communications that
              are not your own Content or to otherwise interfere with the
              ability of others to access the TF MFB Website;
            </li>
            <li>
              Claim a relationship with or to speak for any business,
              association, institution or other organization for which you are
              not authorized to claim such a relationship;
            </li>
            <li>
              Violate any operating rule, policy or guideline of your Internet
              access provider or online service.
            </li>
          </ul>
        </p>

        <h2>TF MFB Website Content</h2>
        <p>
          <b>By this agreement:</b>
          <br />
          <b>(a)</b> You acknowledge that all Content on the TF MFB Website is
          generally provided by TF MFB. You acknowledge that TF MFB Website
          permits access to Content that is protected by copyrights, trademarks,
          and other proprietary (including intellectual property) rights
          ("Intellectual Property Rights"), and that these Intellectual Property
          Rights are valid and protected in all media existing now or later
          developed and except as is explicitly provided below, your use of
          Content shall be governed by applicable copyright and other
          intellectual property laws.
          <br />
          <b>(b)</b> You may not modify, copy, reproduce, transmit, distribute,
          publish, create derivative works from, display or otherwise transfer
          or commercially exploit any of the Content, in whole or in part,
          provided, however, that you may (i) make a reasonable number of
          digital or other form of copies to permit your computer hardware and
          software to access and view the Content, (ii) print one copy of each
          piece of Content, (iii) make and distribute a reasonable number of
          copies of Content, in whole or in part, in hard copy or electronic
          form for internal use only. Any permitted copies of Content must
          reproduce in an unmodified form any notices contained in the Content,
          such as all Intellectual Property Right notices, and an original
          source attribution to "The TF MFB Website" and its URL address. You
          acknowledge that the TF MFB Website, its Contributors, and/or Users
          remain the owners of the Content and that you do not acquire any
          Intellectual Property Rights by downloading or printing Content.
        </p>
        <h2>Content Provided By User</h2>
        <p>
          You may upload, transmit, post, publish, reproduce or distribute, on
          or through the TF MFB Website only Content that is not subject to any
          Intellectual Property Rights, or Content in which any holder of
          Intellectual Property Rights has given express authorization for
          distribution over the Internet and on the TF MFB Website, without
          restriction whatsoever. Any Content submitted with the consent of a
          copyright owner other than you should contain a phrase such as
          "Copyright owned by [name of owner]; Used by Permission." By
          submitting Content to any Interactive Area, you automatically grant
          and/or warrant that the owner of such Content, whether it be You or a
          third party, has expressly granted to TF MFB the royalty-free,
          perpetual, irrevocable, non-exclusive, unrestricted, worldwide right
          and license to use, reproduce, modify, adapt, publish, translate,
          create derivative works from, sublicense, distribute, perform, and
          display such Content, in whole or in part, worldwide and/or to
          incorporate it in other works in any form, media, or technology now
          known or later developed for the full term of any Intellectual
          Property Rights that may exist in such Content.
        </p>
        <h2>Interactive Areas</h2>
        <p>
          You acknowledge that the TF MFB Website may include various
          interactive areas ("Interactive Areas"), including but not limited to
          our social media platforms or emailing services. These Interactive
          Areas allow feedback to the TF MFB Website and real-time interaction
          between users. Neither TF MFB, or affiliates, nor their respective
          directors, officers, employees and agents control the contents,
          messages, information, or files delivered to such Interactive Areas.
          However TF MFB has the right to monitor any Interactive Area, from
          time to time and to disclose any information as necessary to satisfy
          any law, regulation or other governmental request, to operate the
          Interactive Area, or to protect itself or other Users. TF MFB also has
          the right to prohibit conduct, communication or Content within an
          Interactive Area, or to edit, refuse to post, or to remove any
          Content, in whole or in part, which it deems in its sole discretion to{" "}
          <b>(i)</b> violate the then-standard provisions of this Agreement or
          any other standard, written TF MFB policy in effect at that time,{" "}
          <b>(ii)</b> be harmful to the rights of any User, TF MFB or other
          third parties, <b>(iii)</b> violate applicable law, or <b>(iv)</b> be
          otherwise objectionable.
        </p>
        <h2>Termination</h2>
        <p>
          TF MFB may terminate or suspend your access to all or any part of the
          TF MFB Website, without notice, for conduct that TF MFB believes is a
          violation of this Agreement or for other conduct which TF MFB believes
          is harmful to TF MFB or other Users. In the event of termination, you
          are no longer authorized to access the TF MFB Website, including the
          Interactive Areas, and the restrictions imposed on you with respect to
          Content downloaded from the TF MFB Website, as well as the disclaimers
          and limitations of liabilities set forth in this agreement, shall
          survive.
        </p>
        <h2>Links</h2>
        <p>
          Neither TF MFB or affiliates, or their respective directors, officers,
          employees, and agents controls, provides, or is responsible for any
          Content, goods or services available through sites on the Internet
          linked to or from the TF MFB Website. All such Content, goods and
          services are made accessible on the Internet by independent third
          parties and are not part of the TF MFB Website or controlled by TF
          MFB. TF MFB neither endorses nor is responsible for the accuracy,
          completeness, usefulness, quality or availability of any Content,
          goods or services available on any site linked to or from the TF MFB
          Website, which are the sole responsibility of such independent third
          parties, and your use thereof is solely at your own risk. Neither TF
          MFB, or affiliates, or their respective directors, officers,
          employees, and agents shall be held responsible or liable, directly or
          indirectly, for any loss or damage caused or alleged to have been
          caused by your use of or reliance on any Content, goods or services
          available on any site linked to TF MFB Website or your inability to
          access the Internet or any site linked to or from the TF MFB Website.
        </p>
        <h2>Disclaimer of Warranties</h2>
        <p>
          By this agreement, you expressly agree that your use of the TF MFB
          Website is at your sole risk. TF MFB or affiliates, or their
          respective directors, officers, employees, warrants that the TF MFB
          Website or any internet site linked to or from the TF MFB Website will
          be uninterrupted or error free, that defects will be corrected, or
          that this site, including the interactive areas, or the server that
          makes it available are free of viruses or other harmful components.
          nor do any of them make any warranty as to the results that may be
          obtained from the use of the TF MFB website or any internet site
          linked to or from the TF MFB Website or as to the timeliness,
          sequence, accuracy, authority, completeness, usefulness,
          non-infringement, reliability, availability, or substance of any
          content, information, service, or transaction provided through the TF
          MFB Website or any site linked to or from the TF MFB Website.
        </p>
        <h2>Limitation of Liabilities</h2>
        <p>
          TF MFB or affiliates, or their respective directors, officers,
          employees, agents, contractors, or licensors, shall not be liable for
          any direct or incidental, special or consequential damages under or
          arising from this agreement, the TF MFB Website, or any Internet site
          linked to or from the TF MFB Website, whether for breach of contract,
          tortuous behavior, negligence, or under any other cause of action,
          including without limitation, any liability for damages caused or
          allegedly caused by any failure of performance, error, omission,
          interruption, electrical surge/damage/interference, deletion, defect,
          delay in operation or transmission, computer virus, communications
          line failure, breakdown of equipment, software error, infringement,
          unauthorized access to, or theft, destruction, alteration, or use of,
          records.
          <br />
          <br />
          TF MFB, or affiliates, or their respective directors, officers,
          employees, agents, contractors, or licensors, shall not be liable to
          you or any other third party for any decision made or action taken by
          you in reliance on the content contained within the TF MFB Website or
          the content contained within any Internet site linked to or from the
          TF MFB Website. By this agreement, you specifically acknowledge and
          agree that TF MFB is not liable for any fraudulent or otherwise
          illegal conduct of any user. If you are dissatisfied with any TF MFB
          Website site content, or with the access agreement of the TF MFB
          Website, in whole or in part, your sole and exclusive remedy is to
          discontinue using the TF MFB Website.
        </p>
        <h2>Indemnity</h2>
        <p>
        By this agreement, you agree to indemnify and hold TF MFB, or affiliates, and their respective directors, officers, employees, and agents from any and all liabilities, claims and expenses, including reasonable attorney’s fees, arising from breach of this agreement, any other policy, your use or access of the TF MFB Website or any Internet site linked to or from the TF MFB Website, or in connection with the transmission of any Content on the TF MFB Website.
        </p>
        <h2>Miscellaneous:</h2>
        <p>This Agreement comprises the entire agreement between TF MFB and you, and supersedes any prior agreements with respect to the subject matter herein. TF MFB may revise this Agreement on a regular basis.</p>
      </div>
    </Layout>
  );
}

export default TermsOfUse