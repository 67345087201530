import { Link } from 'react-router-dom';
import { Button } from '../../../atoms/Button';
import styles from './ServiceCard.module.css';

const ServiceCard = (props) => {
    let popular = props.popular ? "Popular" : null;
    let title, description, details, interestRate, link;
    let features = [];
    switch(props.loanType) {
      case "consumer":
        title ="Consumer Loan";
        description = "It is a loan targeted at salary earners and the working class, at a competitive interest rate.";
        details = "Sometimes, we all need a little extra cash to help us go through hard times. This product affords you the opportunity to meeting variety of needs. Basically, for salary earners who need cash.";
        interestRate = "4%";
        features = ['4% flat interest rate per month.', 'Convenient for all strata of salary earners.', 'Staff must have been in the company for a minimum of One year.', 'Monthly repayment must not exceed Thirty-Three percent of Net Pay.', 'Competitive Interest rate.', 'Flexible repayment plan.'];
        link = "/loans/consumer-loan/"
        break;
        
      case "business":
        title ="Business Loan";
        description = "This loan is specifically for business owners, and can be used to finance working capital.";
        details = "Which is our most requested loan product, is a loan targeted at individuals operating a business. Our Business loan affords you the opportunity to expand, grow as well as sustain your business.";
        interestRate = "6.5%";
        features = ['6.5% flat interest rate per month','Evidence of Business Location.', 'Business must have been existing for at least one year.', 'Flexible repayment Plan.', 'Should produce at least a guarantor.', 'Free advisory service.'];
        link = "/loans/business-loan/"
        break;
      case "education":
        title ="Education Loan";
        description = "No need to worry anymore your child’s Education is assured with our Education Loan.";
        details = "High quality education is often attributed to schools having state-of-the-art infrastructure and modern amenities. At Think Finance MFB, we provide a collateral-free finance product for Private Crèche, Nursery, Primary and Secondary Schools that are duly approved and registered by the...";
        interestRate = "10%";
        features = ['10% interest rate (one-off payment).', 'Minimum of 3 months', 'Flexible repayment Plan.', 'School must be approved and registered by the Ministry of Education and Corporate Affairs Commission'];
        link = "/loans/education-loan/"
        break;
      case "asset-lease":
        title ="Asset-Lease Loan";
        description = "A convenient loan for beginning your equipment-intensive business.";
        details = "You don't need to have all the cash at once, this product affords you the opportunity of a secure and swift loan in purchasing the needful while spreading the installment repayment and conveniently utilizing other cash flow for varied logistics: Killing two birds with one stone.";
        interestRate = "4%";
        features = ['4% flat rate per month', 'Fast and easy way to access funds.', 'Flexible repayment Plan.', 'Thirty Percent of Asset cost price is paid before lease.', 'A guarantor.'];
        link = "/loans/asset-lease-loan/"
        break;
        default:
          return null

    }
    let subDetail = features.splice(0, 1);
    return (
      <>
        <div
          style={{
            background: props.popular ? "#FC6969" : "#D9E2F2",
            ...props.cardStyles,
          }}
          className={[styles.ServiceCard, styles[popular]].join(" ")}
        >
          {props.popular ? (
            <Button
              noBorderRadius
              buttonStyles={{
                background: "#7D0203",
                padding: "5px 0px",
                textTransform: "uppercase",
                marginBottom: "29px",
                fontSize: "14px",
              }}
            >
              Popular
            </Button>
          ) : null}
          <h2>{title}</h2>
          <p>{description}</p>
          <div style={{ color: props.popular ? "#4D0000" : "#012C7F" }}>
            {interestRate}
            <p>{subDetail}</p>
          </div>
          <Link to={link}>
            <Button
              buttonStyles={{
                borderColor: props.popular ? "#620D0D" : "#012C7F",
                color: props.popular ? "#620D0D" : "#012C7F",
                padding: "13px 0px",
              }}
              outlined
            >
              Apply Now
            </Button>
          </Link>
        </div>
        <div
          style={{
            background: props.popular
              ? "linear-gradient(180deg, #FEE6E6 0%, rgba(254, 230, 230, 0) 100%)"
              : "linear-gradient(180deg, #F0F5FF 0%, rgba(240, 245, 255, 0) 100%)",
          }}
          className={[styles.Details, styles[popular]].join(" ")}
        >
          <p>{details}</p>
          <h4>Key Features:</h4>
          <ul style={{ color: props.popular ? "#620D0D" : "#012C7F" }}>
            {features.map((feature) => (
              <li key={Math.random()}>{feature}</li>
            ))}
          </ul>
        </div>
      </>
    );
}

export default ServiceCard
