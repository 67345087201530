import styles from './Logo.module.css';
import {Link} from 'react-router-dom';
import logo from '../../../../assets/images/logo.webp';

const Logo = () => {
  return (
    <Link to="/">
      <img className={styles.Logo} width="190" height="48" src={logo} alt="" />
    </Link>
  );
}

export default Logo