import Layout from '../../hoc/Layout'
import LoanDescription from '../../UI/organisms/LoanDescription/LoanDescription'
import TopSection from '../../UI/organisms/LoanDetailsTopSection/TopSection'
import OtherLoans from '../../UI/organisms/OtherLoans/OtherLoans'
import styles from './LoanDetails.module.css'

const AssetLeaseLoanDetails = () => {
    const description = <p>
    Become an asset owner with a competitive interest rate with on any of Home Appliances, Business Bus etc.
  </p>
    const requirements = <>
    <li>30% deposit on any product with flexible payment plans (up to 6months).</li>
    <li>A guarantor.</li>
    </>
    
    const features = <>
    <li>Fast and easy way to access funds.</li>
    <li>Flexible repayment Plan.</li>
    <li>Free advisory service.</li>
    </>
    const otherLoans = ['consumer', 'business', 'education'];
    return (
        <Layout>
        <div className={styles.LoanDetails}>
            <TopSection title="Asset-Lease Loan" />
            <LoanDescription loanType="assetLease" features={features} requirements={requirements} description={description} /> 
            <OtherLoans otherLoans={otherLoans} />
        </div>
        </Layout> 
    )
}

export default AssetLeaseLoanDetails
