import { TextButton } from "../../atoms/Button";
import Card from "../../molecules/Card/Card";
import styles from "./OurServices.module.css";
import right from '../../../../assets/icons/right.png';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";

const OurServices = () => {
  return (
    <div className={styles.OurServices}>
      <h5>Our Services</h5>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={4}>
          <div className={styles.TextCard}>
            <h3>Need a Loan</h3>
            <p>
              Do you need money to boost your business, buy a new car, pay
              school fees? We can offer you loans up to ₦5,000,000, conveniently
              spread over 3-12 months with a flexible enough payment plan.
            </p>
            <Link to="/loans">
            <div className={styles.textbuttonwrap}>
              <TextButton>
                View all loan options <img src={right} alt="" />
              </TextButton>
            </div>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card loanType="consumer" color="green" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card loanType="business" color="" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card loanType="education" color="" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card loanType="asset-lease"  color="green" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <div className={styles.TextCard2}>
            <h3>Come bank with us.</h3>
            <p>
              We offer a variety of accounts that are tailored just for you.
            </p>
            <Link to="/loans">
            <TextButton style={{ float: "right" }}>View All Loans</TextButton>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurServices;
