import { Button } from '../../atoms/Button'
import styles from './LoanDescription.module.css'

const LoanDescription = (props) => {
    let setGlobalLoanType;
    switch(props.loanType) {
      case "consumer":
        setGlobalLoanType = () => localStorage.setItem("loanType", "Consumer Loan");
        break;
      case "education":
        setGlobalLoanType = () => localStorage.setItem("loanType", "Education Loan");
        break;
      case "business":
        setGlobalLoanType = () => localStorage.setItem("loanType", "Business Loan");
        break;
      case "assetLease":
        setGlobalLoanType = () => localStorage.setItem("loanType", "Asset Lease Loan");
        break;
        default:
          return;

    }
    return (
      <div className={styles.LoanDescription}>
        <div className={styles.Wrapper}>
        <div className={styles.MainContent}>
          {props.description}
          <div className={styles.ButtonWrap}>
            <a href="https://tfmicrofinance.evolvecredit.io/auth/register">
            <Button clicked={setGlobalLoanType} buttonStyles={{ padding: "16px 30.5px", fontSize: "16px" }}>
              Apply For Loan
            </Button>
            </a>
          </div>
        </div>
        <div className={styles.Features}>
          <div>
            <h3>Features</h3>
            <ul>
              {props.features}
            </ul>
          </div>
          <div>
            <h3>Requirements</h3>
            <ul>
              {props.requirements}
            </ul>
          </div>
        </div>
        </div>
      </div>
    );
}

export default LoanDescription
