import Layout from "../../hoc/Layout";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className={styles.PrivacyPolicy}>
        <h1>Privacy Policy</h1>
        <h2>Personal Data</h2>
        <p>
          Under the Nigeria Data Protection Regulation, personal data is defined
          as: “Any information relating to an identified or identifiable natural
          person ('data subject'); an identifiable natural person is one who can
          be identified, directly or indirectly, in particular by reference to
          an identifier such as a name, an identification number, location data,
          an online identifier or to one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural or social
          identity of that natural person”.
        </p>
        <h2>Who Is Responsible for Your Personal Information?</h2>
        <p>
          TF Microfinance Bank Limited is your data controller and is
          responsible for processing your personal information described in this
          Privacy Policy. TF Microfinance Bank Limited uses the personally
          identifiable information you provide to communicate with you in
          response to your enquiries, to provide the services you request, and
          to manage your account. Any personally identifiable information
          gathered by TF Microfinance Bank Limited will be used for these
          purposes only. We will not disclose or divulge such data outside of TF
          Microfinance Bank Limited, other than to third party providers that
          assist us in providing the services. TF Microfinance Bank Limited does
          not share, sell, rent or trade personally identifiable information
          with third parties for their promotional purposes.
        </p>
        <h2>
          Why does TF Microfinance Bank Limited need to collect and store
          personal data?
        </h2>
        <p>
          In order for us to provide you with a service, we need to collect
          personal data. When you sign up for any TF Microfinance Bank Limited
          Online Solution or other TF Microfinance Bank Limited service or
          promotion that is responsible for processing your personal information
          described in this Privacy Policy. TF Microfinance Bank Limited uses
          the personally identifiable information you provide to communicate
          with you in response to your enquiries, to provide the services you
          request, and to manage your account. Any personally identifiable
          information gathered by TF Microfinance Bank Limited will be used for
          these purposes only. We will not disclose or divulge such data outside
          of TF Microfinance Bank Limited, other than to third party providers
          that assist us in providing the services. TF Microfinance Bank Limited
          does not share, sell, rent or trade personally identifiable
          information with third parties for their promotional purposes. Why
          does TF Microfinance Bank Limited need to collect and store personal
          data? In order for us to provide you with a service, we need to
          collect personal data. When you sign up for any TF Microfinance Bank
          Limited Online Solution or other TF Microfinance Bank Limited service
          or promotion that requires registration, we ask you for personal
          information (such as your name, email address and an account
          password). For certain services, such as our payment services, we
          require your debit or credit card information. We typically do not
          store this information and when we do, we maintain the data in
          encrypted form on secure servers. We may combine the information you
          submit under your account with information from other TF Microfinance
          Bank Limited services or third parties in order to provide you with a
          better experience and to improve the quality of our services. In any
          event, we are committed to ensuring that the information we collect
          and use is appropriate for this purpose, and does not constitute an
          invasion of your privacy. In terms of being contacted for marketing
          purposes TF Microfinance Bank Limited would contact you for additional
          consent.
        </p>
        <h2>
          Will TF Microfinance Bank Limited share my personal data with anyone
          else?
        </h2>
        <p>
          TF Microfinance Bank Limited only shares personal information with
          other companies or individuals in the following limited circumstances:
          <ul>
            <li>
              We have your consent. We require consent for the sharing of any
              sensitive personal information
            </li>
            <li>
              We provide such information to our subsidiaries, affiliated
              companies or other trusted businesses or persons for the purpose
              of processing personal information on our behalf. We require that
              these parties agree to process such information based on our
              instructions and in compliance with the Nigeria Data Protection
              Regulation and any other appropriate confidentiality and security
              measures. When they no longer need your data to fulfil this
              service, they will dispose of the details in line with TF
              Microfinance Bank Limited.
            </li>
            <li>
              We have a good faith belief that access, use, preservation or
              disclosure of such information is reasonably necessary to{" "}
              <b>(a) </b>
              satisfy any applicable law, regulation, legal process or
              enforceable governmental request, <b>(b)</b> enforce applicable
              Terms of Service, including investigation of potential violations
              thereof,
              <b> (c)</b> detect, prevent, or otherwise address fraud, security
              or technical issues, or <b>(d)</b> protect against imminent harm
              to the rights, property or safety of TF Microfinance Bank Limited
              , its users or the public as required or permitted by law.
            </li>
            <li>
              If TF Microfinance Bank Limited becomes involved in a merger,
              acquisition, or any form of sale of some or all of its assets, we
              will provide notice before personal information is transferred and
              becomes subject to a different privacy policy.
            </li>
          </ul>
        </p>
        <h2>
          How will TF Microfinance Bank Limited use the personal data it
          collects about me?
        </h2>
        <p>
          TF Microfinance Bank Limited will process (collect, store and use) the
          information you provide in a manner compatible with the Nigeria Data
          Protection Regulation (NDPR). We review our data collection, storage
          and processing practices to ensure that we only collect, store and
          process the personal information needed to provide or improve our
          services. We will endeavour to keep your information accurate and up
          to date, and not keep it for longer than is necessary, but we depend
          on our users to update or correct their personal information whenever
          necessary. TF Microfinance Bank Limited is required to retain
          information in accordance with the law, such as information needed for
          income tax and audit purposes. How long certain kinds of personal data
          should be kept may also be governed by specific business-sector
          requirements and agreed practices. Personal data may be held in
          addition to these periods depending on individual business needs.
          <br /> <br />
          TF Microfinance Bank Limited only processes personal information for
          the purposes described in this Privacy Policy and/or the supplementary
          privacy notices for specific services. In addition to the above, such
          purposes include:
          <ul>
            <li>
              Providing our services to users, including the display of
              customized content;
            </li>
            <li>
              Auditing, research and analysis in order to maintain, protect and
              improve our services;
            </li>
            <li>Ensuring the technical functioning of our network; and</li>
            <li>Developing new services.</li>
          </ul>
          <br />
          You can find more information about how we process personal
          information by referring to the supplementary privacy notices for
          particular services.
        </p>
        <h2>Log information</h2>
        <p>
          When you access TF Microfinance Bank Limited services, our servers
          automatically record information that your browser sends whenever you
          visit a website.
        </p>
        <h2>User communications</h2>
        <p>
          When you send email or other communications to TF Microfinance Bank
          Limited, we may retain those communications in order to process your
          inquiries, respond to your requests and improve our services.
        </p>
        <h2>Affiliated sites</h2>
        <p>
          We offer some of our services in connection with other web sites e.g.
          Websites of our subsidiaries. Personal information that you provide to
          those sites may be sent to TF Microfinance Bank Limited in order to
          deliver the service. We process such information in accordance with
          this Privacy Policy. The affiliated sites may have different privacy
          practices and we encourage you to read their privacy policies.{" "}
        </p>
        <h2>
          Under what circumstances will TF Microfinance Bank Limited contact me?
        </h2>
        <p>
          Our aim is not to be intrusive, and we undertake not to ask irrelevant
          or unnecessary questions. Moreover, the information you provide will
          be subject to rigorous measures and procedures to minimize the risk of
          unauthorized access or disclosure.
        </p>
        <h2>
          Can I find out the personal data that TF Microfinance Bank Limited
          holds about me?
        </h2>
        <p>
          TF Microfinance Bank Limited at your request, can confirm what
          information we hold about you and how it is processed. If TF
          Microfinance Bank Limited does hold personal data about you, you can
          request the following information:
          <ul>
            <li>
              Contact details of the data protection officer, where applicable.
            </li>
            <li>
              The purpose of the processing as well as the legal basis for
              processing.
            </li>
            <li>
              If the processing is based on the legitimate interests of TF
              Microfinance Bank Limited or a third party, information about
              those interests.
            </li>
            <li>
              The categories of personal data collected, stored and processed.
            </li>
            <li>
              Recipient(s) or categories of recipients that the data is/will be
              disclosed to.
            </li>
            <li>
              or disclosure. Can I find out the personal data that TF
              Microfinance Bank Limited holds about me? TF Microfinance Bank
              Limited at your request, can confirm what information we hold
              about you and how it is processed. If TF Microfinance Bank Limited
              does hold personal data about you, you can request the following
              information:
              <br /> • Contact details of the data protection officer, where
              applicable.
              <br /> • The purpose of the processing as well as the legal basis
              for processing.
              <br /> • If the processing is based on the legitimate interests of
              TF Microfinance Bank Limited or a third party, information about
              those interests.
              <br /> • The categories of personal data collected, stored and
              processed.
              <br /> • Recipient(s) or categories of recipients that the data
              is/will be disclosed to.
              <br /> • If we intend to transfer the personal data to a third
              party or international organization, information about how we
              ensure this is done securely.
              <br /> The Attorney General of the Federation will approve sending
              personal data to some countries because they meet a minimum
              standard of data protection. In other cases, we will ensure there
              are specific measures in place to secure your information.
            </li>
            <li>How long the data will be stored.</li>
            <li>
              Details of your rights to correct, erase, restrict or object to
              such processing.
            </li>
            <li>
              Information about your right to withdraw consent at any time.
            </li>
            <li>
              How to lodge a complaint with the supervisory authority (NITDA).
            </li>
            <li>
              Whether the provision of personal data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract, as well as whether you are obliged to provide the
              personal data and the possible consequences of failing to provide
              such data.
            </li>
            <li>
              The source of personal data if it wasn’t collected directly from
              you.
            </li>
            <li>
              Any details and information of automated decision making, such as
              profiling, and any meaningful information about the logic
              involved, as well as the significance and expected consequences of
              such processing.
            </li>
          </ul>
        </p>
        <h2>What other data privacy rights do you have?</h2>
        <p>
          Although not always absolute, you also have some other rights. At any
          point, while TF Microfinance Bank Limited is in possession of or
          processing your personal data, you, the data subject, have the right
          to:
          <ul>
            <li>
              Any details and information of automated decision making, such as
              profiling, and any meaningful information about the logic
              involved, as well as the significance and expected consequences of
              such processing.
            </li>
            <li>
              Correct your data in our custody that is inaccurate or incomplete.
            </li>
            <li>
              Ask for the data we hold about you to be erased from our systems.
            </li>
            <li>
              Restrict processing of your personal data where certain conditions
              apply.
            </li>
            <li>
              Have the data we hold about you transferred to another
              organization.
            </li>
            <li>
              Object to certain types of processing, such as direct marketing
            </li>
            <li>
              Object to automated processing like profiling, as well as the
              right to be subject to the legal effects of automated processing
              or profiling.
            </li>
          </ul>
          <br />
          Where a third party is involved in the processing of your personal
          data, all of the above requests will be forwarded, as appropriate, to
          the TF Microfinance Bank Limited Data Protection Officer.
        </p>
        <h2>
          What forms of ID will I need to provide in order to access this?
        </h2>
        <p>
          TF Microfinance Bank Limited accepts the following forms of ID (but
          not limited to) when information on your personal data is requested:
          International Passport, driving license, national identity card,
          permanent voter card.
        </p>
        <h2>More information</h2>
        <p>
          Please do not hesitate to get in touch with us via the contact page on
          our website if you have any questions or queries regarding our privacy
          policy. To contact our Data Protection Officer, kindly address your
          request to “The Data Protection Officer” at{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://maps.google.com/maps/dir//ThinkFinance+Microfinance+Bank+House+35+51+Rd+Festac+Town+102102+Lagos/@6.46986,3.2805421,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x103b8901ba33d7ad:0xb1798e0647f6315e"
            style={{ color: "#012C7F" }}
          >
            51 Road, House 35, Beside Slot shop, Festac Town, Lagos Nigeria{" "}
          </a>
          or send an email to <em> </em>
          <a href="mailto:dpo@thinkfinancemfb.com" style={{ color: "#012C7F" }}>
            dpo@thinkfinancemfb.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
