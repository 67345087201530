import styles from "./Card.module.css";
import line from "../../../../assets/icons/Line.png";
import { TextButton } from "../../atoms/Button";
import { Link } from "react-router-dom";

const Card = (props) => {
  let title, description;
  switch (props.loanType) {
    case "consumer":
      title = "Consumer Loan";
      description =
        "This is a loan targeted at salary earners and the working class, at a competitive interest rate.";
      break;
    case "business":
      title = "Business Loan";
      description =
        "This is our most requested loan product, is a loan targeted at individuals operating a business";
      break;
    case "education":
      title = "Education Loan";
      description =
        "No need to worry anymore your child’s Education is assured with our Education Loan.";
      break;
    case "asset-lease":
      title = "Asset Lease Loan";
      description =
        "This loan applies to the acquisition of Tricycle, Home appliances, shuttle buses etc. at different rates respectively.";
      break;
    default:
      return null;
  }
  return (
    <div
      style={{
        boxShadow:
          props.color === "green"
            ? "0px 20px 40px rgba(22, 22, 22, 0.1), inset 0px 12px 0px #42BE65"
            : "0px 20px 40px rgba(22, 22, 22, 0.1), inset 0px 12px 0px #0F62FE",
      }}
      className={styles.Card}
    >
      <div id={styles.top}></div>
      <div className={styles.CardContent}>
        <h3>{title}</h3>
        <img src={line} alt="" />
        <p>{description}</p>
        <Link to={`/loans/${title.toLowerCase().split(' ').join('-')}`}>
        <TextButton>Apply For This Loan</TextButton>
        </Link>
      </div>
    </div>
  );
};

export default Card;
