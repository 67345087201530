import styles from './TeamCard.module.css';
import bamidele from '../../../../../assets/images/bambam.jpeg';
import Efeurhobo from '../../../../../assets/images/Efeurhobo-1.webp';
import alab from '../../../../../assets/images/martin-alabson.jpg';
import kes from '../../../../../assets/images/kes.jpg';
import temibod from '../../../../../assets/images/temibod.jpg';
import tunde from '../../../../../assets/images/tunde.jpg';

const TeamCard = (props) => {
    let name, position, description, image;
    switch (props.boardMember) {
        case "manager":
          name = "Adeagbo Babatunde Paul";
          position = "Director"
          description =
            "An experienced Chartered Accountant with over 14 years’ experience. An Associate member of the Institute of Chartered Accountants of Nigeria (ICAN) and the Chartered Institute of Taxation of Nigeria (CITN). Currently serve as Deputy Manager at Federal Inland Revenue Service of Nigeria (FIRS).";
            image = tunde
          break;
        case "chairman":
          name = "Bamidele Obafemi Olufela";
          position = "Chairman"
          description =
            "An experienced Investment Banker, he started his professional career with an internship with Procter & Gamble Nigeria Ltd in 2005.";
            image = bamidele
          break;
        case "non-executive":
          name = "Godfrey Efeurhobo";
          position = "Director"
          description =
            "An experienced executive level professional with over 22 years’ experience in transformational leadership, stakeholder management, strategy, negotiation, business planning. Membership of the Institute of Chartered Secretaries and Administrators of Nigeria (ICSAN), the Institute of Chartered Mediators and Conciliators (ICMC) and the Nigerian Bar Association (NBA).Currently serves as Group Chief Commercial Officer at Africell Group.";
            image = Efeurhobo;
          break;
        case "non-executive-2":
          name = "Adegbuyi Taiwo Kesington";
          position = "Director"
          description =
            "A Business manager with over 20 years’ experience in Relationship management in the banking industry. Currently serves as Head, Client Relationship management at Halogen Security's.";
            image = kes;
          break;
        case "non-executive-3":
          name = "Alabson Martin";
          position = "Director"
          description =
            "A finance professional with 14 years extensive in Audit, Corporate Accounting and Tax Management. An Associate member of the Institute of Chartered Accountants of Nigeria (ICAN), the Chartered Institute of Taxation of Nigeria (CITN), the Association of Chartered Certified Accountant (ACCA) and the Institute  of Internal Auditors (IIA). Currently serves as General Manager, Finance at Platform Petroleum.";
            image = alab;
          break;
        case "non-executive-4":
          name = "Temitope Iyabo Ajayi";
          position = "Director";
          description =
            "A professional banker with over 18 years’ experience in corporate banking & customer service management. Currently serve as Assistant Manager at Ecobank.";
            image = temibod;
          break;
        case "non-executive-5":
          name = "Babatunde Paul";
          position = "Director";
          description =
            "An experienced Accountant with a very strong background in finance and years of experience in Banking and Taxation. He started is professional career with Oceanic Bank before moving to Federal Inland Revenue Service (FIRS).";
            image  = tunde
          break;
        default:
          return null;
      }
    return (
        <div className={styles.TeamCard}>
            <img src={image} alt={name} />
            <h2>{name}</h2>
            <p className={styles.Position}>{position}</p>
            <p>{description}</p>
        </div>
    )
}

export default TeamCard
