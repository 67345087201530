import Header from "../UI/organisms/Header/Header";
import Footer from "../UI/organisms/Footer/Footer";
import { useLayoutEffect } from "react";

const Layout = ({ children }) => {
  useLayoutEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout