import styles from "./Input.module.css";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

const useStyles = makeStyles({
  root: {
    outline: "none",
    border: "none",
    fontFamily: "IBM Plex Sans",
  },
});

export const SelectDate = (props) => {
  const { handleChange, value, placeholder } = props;
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        views={
          props.type === "month-year"
            ? ["year", "month"]
            : ["year", "month", "day"]
        }
        label={placeholder}
        value={value}
        openTo="year"
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        renderInput={(params) => (
          <>
          <TextField
            style={props.style}
            size="small"
            className={[styles.DateInput, classes.root].join(" ")}
            {...params}
          />
          </>
        )}
      />
      
    </LocalizationProvider>
  );
};

export const SelectInput = (props) => {
  const { handleChange, value, placeholder, options, selectType } = props;

  const classes = useStyles();

  return (
    <div>
      <FormControl
        error={props.error ? true : false}
        className={classes.root}
        size="small"
        style={{ width: "100%", marginBottom: "36px", padding: 0 }}
      >
        <InputLabel id={styles.SelectLabel}>{placeholder}</InputLabel>
        <Select
          className={[styles.LoanSelect, classes.root].join(" ")}
          labelId="select"
          id={selectType}
          name={selectType}
          value={value}
          label={placeholder}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem
                id={styles.SelectLabel}
                key={Math.random()}
                value={option}
              >
                {props.selectType === "loanTenure" ? option + " months" : option}
              </MenuItem>
            );
              
          })}
        </Select>
        {props.helper ? (
          <em className={styles.HelperText}>{props.helper}</em>
        ) : null}
      </FormControl>
    </div>
  );
};
export const InputSelect = (props) => {
  const { handleChange, value, options, selectType } = props;

  const classes = useStyles();

  return (
    <div>
      <FormControl
        error={props.error ? true : false}
        className={classes.root}
        size="small"
        style={{ width: "100%", padding: 0 }}
      >
        <Select
          value={value}
          onChange={handleChange}
          name={selectType}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className={styles.Select}
        >
          {!props.duration? options.map((option) => {
            return (
              <MenuItem
                id={styles.SelectLabel}
                key={Math.random()}
                value={option}
              >
                {option}
              </MenuItem>
            );
          }) : options.map((option) => {
            return (
              <MenuItem
                id={styles.SelectLabel}
                key={Math.random()}
                value={option}
              >
                {option + " months"}
              </MenuItem>
            );
          })}
        </Select>
        {props.helper ? (
          <em className={styles.HelperText}>{props.helper}</em>
        ) : null}
      </FormControl>
    </div>
  );
};

export const Input = (props) => {
  return (
    <div className={styles.Wrap}>
      <input
        className={styles.Input}
        style={{ border: props.error ? "1px solid red" : null }}
        {...props}
      />
      {props.helper ? (
        <em className={styles.HelperText}>{props.helper}</em>
      ) : null}
    </div>
  );
};

export const LoanInput = (props) => {
  return (
    <div className={styles.InputWrap}>
      <input
        className={styles.LoanInput}
        style={{ border: props.error ? "1px solid red" : null }}
        {...props}
      />
      {props.helper ? (
        <em className={styles.HelperText}>{props.helper}</em>
      ) : null}
    </div>
  );
};

export const FileInput = props => {
  return (
    <div className={styles.InputWrap}>
      <input
        required
        name={props.name}
        id={props.id}
        type="file"
        placeholder={props.placeholder}
        className={styles.FileInput}
        onChange={props.onChange}
      />
      {props.helper ? (
        <em
          style={{ color: props.error ? "red" : null }}
          className={styles.HelperText}
        >
          {props.helper}
        </em>
      ) : null}
    </div>
  );
}
