import styles from "./Footer.module.css";
import icon1 from "../../../../assets/icons/icons8-twitter.svg";
import icon2 from "../../../../assets/icons/icons8-linkedin.svg";
import icon3 from "../../../../assets/icons/icons8-instagram.svg";
import icon4 from "../../../../assets/icons/icons8-facebook.svg";
import auditImage from '../../../../assets/images/audit.webp';
import cbn from '../../../../assets/images/CBN Logo.png';
import ndic from '../../../../assets/images/NDIC Logo.png';
import { Link } from "react-router-dom";
import Logo from "../../atoms/Logo/Logo";

function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.Wrapper}>
        <ul className={styles.MainList}>
          <li>
            <Logo />
          </li>
          <li>
            51 Road, House 35, Beside Slot shop. Festac Town, Lagos Nigeria.
          </li>
          <li>
            (+234) 818 5453 116
            <br />
            (+234) 812 5189 602
          </li>
          <li>info@thinkfinancemfb.com</li>
          <ul className={styles.SocialLinks}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/ThinkFinanceMFB"
            >
              <li>
                Twitter <img src={icon1} alt="" />
              </li>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/tf-microfinance-bank"
            >
              <li>
                LinkedIn <img src={icon2} alt="" />
              </li>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/tfmicrofinancebank/"
            >
              <li>
                Instagram <img src={icon3} alt="" />
              </li>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/thinkfinancemicrofinancebank"
            >
              <li>
                Facebook <img src={icon4} alt="" />
              </li>
            </a>
          </ul>
        </ul>

        <ul className={styles.listWrap}>
          <li>Company</li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>Careers</li>
          <li><a href="/about-us#board">Board</a></li>
        </ul>
        <ul className={styles.listWrap}>
          <li>Services</li>
          <li>
            <Link to="/loans">Loans</Link>
          </li>
          <li>
            <a href="/loans#accounts">Accounts</a>
          </li>
          <li>Esusu</li>
          <li>GOcash</li>
          <li>FinCorp</li>
        </ul>
        <ul className={styles.listWrap}>
          <li>
            <Link to="/about-us">Questions</Link>
          </li>

          <li>
            <a href="/loans#faq">FAQ</a>
          </li>
          <li>
            <a href="/about-us#contact">Contact Us</a>
          </li>
        </ul>
      </div>
      <div className={styles.LogoWrap}>
        <img className={styles.auditImage} src={auditImage} alt="npdr audit" />
        <img className={styles.auditImage} src={cbn} alt="cbn" />
        <img className={styles.auditImage} src={ndic} alt="ndic" />
      </div>
      <div className={styles.FooterBottom}>
        <ul>
          <li>
            {" "}
            <Link to="/terms-of-use">Terms of Use</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/loan-policy">Loan Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
