import { Grid } from '@mui/material';
import styles from './LoanServices.module.css';
import ServiceCard from './ServiceCard/ServiceCard';

const LoanServices = () => {
    return (
      <div id="accounts" className={styles.LoanServices}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <div className={styles.Text}>
              Open an <span>account</span> and dive into a world of
              opportunities.
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
              <ServiceCard accountType="target-savings" popular />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
              <ServiceCard accountType="daily-savings" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
              <ServiceCard accountType="current-savings" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
              <ServiceCard accountType="investment" popular />
          </Grid>
        </Grid>
      </div>
    );
}

export default LoanServices
