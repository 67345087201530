import Layout from "../../hoc/Layout";
import styles from "./CookiePolicy.module.css";

const CookiePolicy = () => {
  return (
    <Layout>
      <div className={styles.CookiePolicy}>
        <h1>COOKIE POLICY</h1>
        <h2>About Cookies</h2>
        <p>
          Cookies are a kind of short-term memory for the web. They are stored
          in your browser and enable a site to ‘remember’ little bits of
          information between pages or visits. Cookies can be used by web
          servers to identify and track users as they navigate different pages
          on a website, and to identify users returning to a website. Cookies
          may be either “persistent” cookies or “session” cookies. A persistent
          cookie consists of a text file sent by a web server to a web browser,
          which will be stored by the browser and will remain valid until its
          set expiry date (unless deleted by the user before the expiry date). A
          session cookie, on the other hand, will expire at the end of the user
          session, when the web browser is closed.
        </p>
        <h2>Cookies on our Website</h2>
        <p>
          We use both SESSION COOKIES (which expire once you close your web
          browser) and PERSISTENT COOKIES (which stay on your device until you
          delete them). We have also grouped our cookies into the following
          categories, to make it easier for you to understand why we need them:
          <br /> <br />
          (a) <b>Functionality</b>: These cookies enable technical performance
          of our websites and allow us to ‘remember’ the choices you make and
          your preferences.
          <br /> (b) <b>Performance/Analytical</b>: These cookies allow us to
          collect certain information about how you navigate the Sites. They
          help us to understand which parts of our websites are interesting to
          you and which are not as well as what we can do to improve them.
        </p>
        <h2>How We Use Cookies</h2>
        <p>
          Cookies do not contain any information that personally identifies you,
          but personal information that we store about you may be linked, by us,
          to the information stored in and obtained from cookies. The cookies
          used on this website include those which are strictly necessary
          cookies for access and navigation, cookies that track usage
          (performance cookies) and remember your choices (functionality
          cookies). We may use the information we obtain from your use of our
          cookies for the following purposes:
          <br />
          <li>to recognize your computer when you visit our website,</li>
          <li>to track you as you navigate our website,</li>
          <li>
            to improve the website’s usability i.e. our Live Chat application to
            answer questions you have in real time,
          </li>
          <li>
            to analyze the use of our website - such as how many people visit us
            each day,
          </li>
          <li>in the administration of our website.</li>
        </p>
        <h2>Third-party cookies</h2>
        <p>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
          <li>
            This site uses Google Analytics which is one of the most widespread
            and trusted analytics solution on the web for helping us to
            understand how you use the site and ways that we can improve your
            experience. These cookies may track things such as how long you
            spend on the site and the pages that you visit so we can continue to
            produce engaging content. For more information on Google Analytics
            cookies, see the official Google Analytics page.
          </li>
          <li>
            We also use social media buttons and/or plugins on this site that
            allow you to connect with your social network in various ways. For
            these to work the following social media sites including; Twitter,
            Linkedin, Facebook, YouTube, Instagram will set cookies through our
            site which may be used to enhance your profile on their site or
            contribute to the data they hold for various purposes outlined in
            their respective privacy policies.
          </li>
        </p>
        <h2>Disabling Cookies</h2>
        <p>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies may affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of this site. Therefore,
          it is recommended that you do not disable cookies.
        </p>
        <h2>More information</h2>
        <p>
          You have the right to take any complaints about how we process your
          personal data to the National Information Technology Development
          Agency (NITDA): <br />{" "}
          <a style={{ color: "#012C7F" }} href="mailto:dpo@nitda.gov.ng">
            dpo@nitda.gov.ng
          </a>
          <br />
          For more details, please address any questions, comments and requests
          regarding our data processing practices to our Data Protection Officer
          via{" "}
          <a style={{ color: "#012C7F" }} href="mailto:dpo@thinkfinancemfb.com">
            dpo@thinkfinancemfb.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default CookiePolicy;
