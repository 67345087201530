import { Grid } from '@mui/material';
import HeroForm from '../HeroForm/HeroForm';
import ServiceCard from './ServiceCard/ServiceCard';
import styles from './TopSection.module.css';

function TopSection() {
    return (
      <div className={styles.TopSection}>
        <div className={styles.top}></div>
        <div className={styles.TopWrapper}>
          <h1 className={styles.MainText}>
            Get simple structured loans with flexible payments
          </h1>
          <HeroForm activePage="services" />
        </div>
        <div className={styles.Wrapper}>
          <Grid
            style={{ display: "flex", alignItems: "flex-start" }}
            container
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={3}>
              <ServiceCard loanType="consumer" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ServiceCard loanType="business" popular />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ServiceCard loanType="education" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ServiceCard loanType="asset-lease" />
            </Grid>
          </Grid>
        </div>
      </div>
    );
}

export default TopSection
