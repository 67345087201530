import Layout from '../../hoc/Layout'
import LoanDescription from '../../UI/organisms/LoanDescription/LoanDescription'
import TopSection from '../../UI/organisms/LoanDetailsTopSection/TopSection'
import OtherLoans from '../../UI/organisms/OtherLoans/OtherLoans'
import styles from './LoanDetails.module.css'

const EducationLoanDetails = () => {
    const description = <p>
    We ensure school owners get student’s fee at the onset of school resumption date while parent have the opportunity to spread the fee payable monthly at a very low interest rate.
  </p>
    const requirements = <>
    <li>Must have a mutual agreement between the parent and the school principal.</li>
    <li>At least a guarantor must be produced.</li>
    </>
    
    const features = <>
    <li>Flexible repayment Plan.</li>
    <li>Minimum of 3 months</li>
    </>
    const otherLoans = ['consumer', 'asset-lease', 'business'];
    return (
        <Layout>
        <div className={styles.LoanDetails}>
            <TopSection title="Education Loan" />
            <LoanDescription loanType="education" features={features} requirements={requirements} description={description} />
            <OtherLoans otherLoans={otherLoans} />
        </div>
        </Layout>
    )
}

export default EducationLoanDetails
