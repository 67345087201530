import FormSection from '../../UI/organisms/LoanFormSection/FormSection';
import LoanSideBar from '../../UI/organisms/LoanSideBar/LoanSideBar';
import styles from './LoanForm.module.css';

function LoanForm() {
  return (
    <div className={styles.LoanForm}>
        <LoanSideBar />
        <FormSection />
    </div>
  )
}

export default LoanForm