import styles from './Gallery.module.css';
import img1 from '../../../../assets/images/galleryimage1.webp';
import img2 from '../../../../assets/images/galleryimage2.webp';
import img3 from '../../../../assets/images/galleryimage3.webp';
import img4 from '../../../../assets/images/galleryimage4.webp';
import img5 from '../../../../assets/images/galleryimage5.webp';
import img6 from '../../../../assets/images/galleryimage6.webp';
import Grid from '@mui/material/Grid';



function Gallery() {
  return (
    <div className={styles.Gallery}>
        <Grid container spacing={2}>
            <Grid item xs={6} sm={7}>
                <img src={img1} alt="" className={styles.Image} />
            </Grid>
            <Grid item xs={6} sm={5}>
                <img src={img2} alt="" className={styles.Image} />
            </Grid>
            <Grid item xs={6} sm={5}>
                <img src={img3} alt="" className={styles.Image} />
            </Grid>
            <Grid item xs={6} sm={7}>
                <img src={img4} alt="" className={styles.Image} />
            </Grid>
            <Grid item xs={6} sm={6}>
                <img src={img5} alt="" className={styles.Image} />
            </Grid>
            <Grid item xs={6} sm={6}>
                <img src={img6} alt="" className={styles.Image} />
            </Grid>
        </Grid>
    </div>
  );
}

export default Gallery;
