import styles from './Header.module.css';
import { useState } from 'react';
import NavLinks from '../../molecules/NavLinks/NavLinks';
import burger from '../../../../assets/icons/burger.svg';
import MobileMenu from '../../molecules/MobileMenu/MobileMenu';
import Logo from '../../atoms/Logo/Logo';

const Header = () => {
    const [menu, showMenu] = useState(false);

    const handleMenu = () => {
        showMenu(!menu)
    }

    return (
      <>
        <header className={styles.Header}>
          <Logo />
          <NavLinks />
          <img
            id={styles.burgerIcon}
            src={burger}
            alt=""
            onClick={handleMenu}
          />
        </header>
        <MobileMenu handleMenu={handleMenu} menuState={menu} />
      </>
    );
}

export default Header
