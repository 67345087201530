import { useState } from "react";
import styles from "./LoanProcess.module.css";

function LoanProcess(props) {
  const { numberOfSteps } = props;
  const [activeStep, setActiveStep] = useState(1);
  let stepTitle, stepDescription;
  const handleSetStep = (stepNum) => {
    if (stepNum === 1) {
      setActiveStep(stepNum);
    } else if (stepNum === 2) {
      setActiveStep(stepNum);
    } else if (stepNum === 3) {
      setActiveStep(stepNum);
    }
  };
  if (activeStep === 1) {
    stepTitle = "Go to the Loan Application Form page";
    stepDescription = (
      <p>
        Click on{" "}
        <a
          style={{ color: "#fd3535" }}
          href="https://tfmicrofinance.evolvecredit.io/auth/register"
        >
          Apply for Loan
        </a>
        , you will be directed to our Loan Application Form.
      </p>
    );
  } else if (activeStep === 2) {
    stepTitle = "Fill Loan Application Form";
    stepDescription = (
      <p>
        Fill in <span>every</span> input field with the appropriate info and
        click submit it
      </p>
    );
  } else if (activeStep === 3) {
    stepTitle = "Be Patient and wait for feedback";
    stepDescription = (
      <p>
        Be Patient and wait for feedback on the next steps to take. Our
        dedicated team will make sure to reach out to you on the next step.
      </p>
    );
  }
  const arrayItems = Array.from(Array(numberOfSteps).keys());
  return (
    <div className={styles.LoanProcess}>
      <p>{numberOfSteps || "#"} steps in getting your loan approved</p>
      <div className={styles.ProgressBar}>
        {arrayItems.map((item) => {
          const itemNum = item + 1;
          return (
            <span
              onClick={() => handleSetStep(itemNum)}
              key={itemNum}
              className={[
                styles.arrayItem,
                styles[activeStep === itemNum ? "activeStyle" : null],
              ].join(" ")}
              data-item={"step " + itemNum}
            ></span>
          );
        })}
      </div>
      <div className={styles.StepInfo}>
        <h3>{stepTitle}</h3>
        {stepDescription}
      </div>
    </div>
  );
}

export default LoanProcess;
