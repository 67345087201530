import Layout from "../../hoc/Layout";
import TopSection from "../../UI/organisms/AboutTopSection/TopSection";
import Gallery from "../../UI/organisms/Gallery/Gallery";
import Location from "../../UI/organisms/Location/Location";
import OurStory from "../../UI/organisms/OurStory/OurStory";
import OurTeam from "../../UI/organisms/OurTeam/OurTeam";
import styles from "./About.module.css";

const About = () => {
  return (
    <Layout>
      <div className={styles.About}>
        <TopSection />
        <OurStory />
        <Gallery />
        <OurTeam />
        <Location />
      </div>
    </Layout>
  );
};

export default About;
