import Layout from '../../hoc/Layout'
import styles from './NotFound.module.css';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import arrowIcon from '../../../assets/icons/right.png';

const NotFound = (props) => {
  return (
    <Layout>
      <div className={styles.NotFound}>
        <div className={styles.Wrap}>
          <p>Page Not Found</p>
          <div className={styles.RouteLinks}>
              <Link to="/"><span>Go Home</span></Link>
              <span onClick={() => props.history.goBack()}><img src={arrowIcon} alt="" />Go Back</span>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(NotFound)