import { Button } from "../../atoms/Button";
import styles from "./Location.module.css";

function Location() {
  return (
    <div id="contact" className={styles.Location}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.408194445083!2d3.2783534144494304!3d6.469865325580285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8901ba33d7ad%3A0xb1798e0647f6315e!2sThinkFinance%20Microfinance%20Bank!5e0!3m2!1sen!2sng!4v1640630824737!5m2!1sen!2sng"
        height="450"
        className={styles.map}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
      <div className={styles.Content}>
        <h2>Come Find Us</h2>
        <p>House 35,51 Rd, Festac Town, Lagos</p>
        <a href="mailto:info@thinkfinancemfb.com">info@thinkfinancemfb.com</a>
        <div className={styles.ButtonWrap}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://maps.google.com/maps/dir//ThinkFinance+Microfinance+Bank+House+35+51+Rd+Festac+Town+102102+Lagos/@6.46986,3.2805421,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x103b8901ba33d7ad:0xb1798e0647f6315e"
          >
            <Button outlined>Get Directions</Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Location;
