import { BrowserRouter, Switch, Route } from "react-router-dom";
import ConsumerLoanDetails from "./components/templates/LoanDetails/ConsumerLoanDetails";
import BusinessLoanDetails from "./components/templates/LoanDetails/BusinessLoanDetails";
import EducationLoanDetails from "./components/templates/LoanDetails/EducationLoanDetails";
import AssetLeaseLoanDetails from "./components/templates/LoanDetails/AssetLeaseLoanDetails";
import AboutPage from "./pages/About";
import HomePage from "./pages/Home";
import LoanFormPage from "./pages/LoanForm";
import ServicesPage from "./pages/Services";
import NotFoundPage from "./pages/NotFound";
import "./App.css";
import { useEffect, useState } from "react";
import Modal from "./components/UI/molecules/Modal/Modal";
import { Link } from "react-router-dom";
import { TextButton } from "./components/UI/atoms/Button";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import TermsOfUsePage from "./pages/TermsOfUse";
import CookiePolicyPage from "./pages/CookiePolicy";
import LoanPolicyPage from "./pages/LoanPolicy";

function App() {
  const [showModal, setShowModal] = useState(false);
  const removeModalHandler = () => {
    setShowModal(false);
    localStorage.setItem("hasModalShown", true);
  };
  let hasModalShown = localStorage.getItem("hasModalShown");
  useEffect(() => {
    setShowModal(!hasModalShown);
  }, [hasModalShown]);
  return (
    <BrowserRouter>
      <>
        <div className="App">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/about-us" component={AboutPage} />
            <Route exact path="/loans" component={ServicesPage} />
            <Route
              path="/loans/consumer-loan"
              component={ConsumerLoanDetails}
            />
            <Route
              path="/loans/business-loan"
              component={BusinessLoanDetails}
            />
            <Route
              path="/loans/education-loan"
              component={EducationLoanDetails}
            />
            <Route
              path="/loans/asset-lease-loan"
              component={AssetLeaseLoanDetails}
            />
            <Route path="/loans/apply" component={LoanFormPage} />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/terms-of-use" component={TermsOfUsePage} />
            <Route path="/loan-policy" component={LoanPolicyPage} />
            <Route path="/cookie-policy" component={CookiePolicyPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </div>
        <Modal show={showModal}>
          {" "}
          <div className="ModalContent">
            To continue using this platform, you agree to all of our{" "}
            <Link style={{ color: "#A8DADC" }} to="/terms-of-use">
              terms of use
            </Link>
            ,{" "}
            <Link to="/privacy-policy" style={{ color: "#A8DADC" }}>
              privacy policy
            </Link>
            , and our{" "}
            <Link to="/cookie-policy" style={{ color: "#A8DADC" }}>
              cookie policy
            </Link>
            <TextButton
              clicked={removeModalHandler}
              style={{ color: "#A8DADC", margin: "0 auto", marginTop: "20px" }}
            >
              I Agree
            </TextButton>
          </div>
        </Modal>
      </>
    </BrowserRouter>
  );
}

export default App;
