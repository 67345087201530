import styles from "./TopSection.module.css";

const TopSection = () => {
  return (
    <div className={styles.TopSection}>
      <h5>About us</h5>
      <h1>
        We’re on a mission to <span>bank the unbanked</span>
      </h1>
      <p>
        A leading Microfinance bank in Nigeria making life better for SME’s and
        MSME’s. We connect the underbanked and unbanked populace to financial
        tools to help their business.{" "}
      </p>
    </div>
  );
};

export default TopSection;
