import { Link } from "react-router-dom";
import { Button } from "../../atoms/Button";
import styles from "./OurBlog.module.css";

const OurBlog = () => {
  return (
    <div className={styles.OurBlog}>
      <h5>Why choose thinkfinance</h5>
      <div className={styles.Wrapper}>
        <div className={styles.Media}></div>
        <div className={styles.Content}>
          <h3>Helping SMEs and MSMEs take the next step</h3>
          <p>
            We offer our services to SME’s and MSME’s providing access to
            convenient retail banking, group lending, granting of loans,
            advances, electronic banking, business advisory, finance leases and
            technical support services to the unbanked and under banked.
          </p>
          <Link to="/about-us">
            <div className={styles.ButtonWrap}>
              <Button>Read More</Button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurBlog;
