import { Grid } from "@mui/material";
import styles from "./FAQ.module.css";

const FAQ = () => {
  const QandA = {
    "What Payment method does TF MFB use to disburse her loan?":
      "The loans are disbursed through Cash or Direct Credit disbursement into customers account domicile in any financial institution.",
    "How much can I borrow? What is the maximum and minimum loan amount?":
      "The minimum loan amount is N5, 000, while the maximum loan amount is N5,000,000. Both are contingent on the borrower's ability to repay the loan, which is based on verifiable business inventory and other criteria.",
    "What is the average time it takes to get a loan?":
      "The loan application takes approximately two working days to process after you've submitted your application.",
    "What are the criteria for getting a loan?":
      "•	Make an application for a loan.     •	Two passport photos.   •	Mode of identification (BVN, driver's license, Permanent Voters Card, or International Passport).      •	Utility bill from the last six months.      •	The presence of a guarantor (a business person or salary earner).      •	Bank Statements for the last 6 months for loans in excess of N400,000.      ",
    "What options do I have for repaying my loan?":
      "You can use your debit card.      You can setup REMITA to repay your loan.      You can transfer money to your TF MFB account from any commercial bank.      You can transfer money to your TF MFB account using Quick teller on ATM machines or your mobile phone.      You can also make a POS deposit into your TF MFB account by visiting any of our Agent Banking officers.      You can repay through cash deposit in any of TF MFB branch office or any stanbic IBTC bank directly to your TF MFB Account.      ",
    "How do I know if I am qualified for a loan?":
      "You must earn a living at a reputable firm as a salary earner or a businessperson who keeps good financial records.",
    "Is it true that Think Finance Microfinance Bank deposits are insured?":
      "Yes. The National Deposit Insurance (NDIC) Corporation of Nigeria insures your deposits with us.",
    "How long must I wait after obtaining a loan before applying for another?":
      "No, there's no need to wait; you may reapply right away.",
    "Is it possible to get a loan extension?":
      "Yes, you may request an extension on your current loan transaction once; however, you will not be permitted to do so a second time.",
    "What is your current rate of interest?":
      "The interest rate is risk based and very competitive",
    "Is it necessary for me to save money before applying for a loan?":
      "While we encourage you to put money aside for a better future, you are not required to do so before applying for a loan.",
    "Can I use my spouse or child as a guarantor?":
      "Family members are not permitted to act as guarantors.",
    "How do I know how much I owe?":
      "Please speak with your account officer or visit any of our branches closest to you.",
    "Is it possible for me to apply for multiple loans at the same time?":
      "No, you cannot take out multiple loans at the same time. However, if you've completed all of the transactions on your previous loan, you can begin a new one.",
    "Is it possible to modify the amount requested?":
      "Yes, the amount applied for can be changed (upwards or downwards).",
    "I've paid off my loan and need a letter stating that I'm no longer in debt.":
      "We can provide a non-indebtedness letter upon request.",
    "How long would it take me to payback my loan?":
      "Depending on the sort of loan taken out, payments can take anywhere from one month to 24 months.",
    "How will I find out if my loan is approved?":
      "Through your loan officer (our staff), you will learn more.",
    "Do I need to make a deposit in order to secure a loan?":
      "In general, not for microloans. Collateral may be required for larger loans, depending on the risk profile.",
    "Do you borrow to people who don't make a steady income?":
      "No, we exclusively lend to business owners and salaried employees.",
  };
  return (
    <div id="faq" className={styles.FrAQ}>
      <h1>Frequently Asked Questions</h1>
      <Grid className={styles.Container} container spacing={2}>
        {Object.keys(QandA).map((item) => (
          <Grid key={item} item xs={12} sm={6}>
            <div className={styles.Question}>
              <h3>{item}</h3>
              <p>{QandA[item]}</p>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FAQ;
